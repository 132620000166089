import React from "react";
import { Card, FormGroup } from "@mui/material";

const Privacy = () => {
  return (
    <Card>
      <FormGroup>
      <h2>Privacy policy</h2>
      </FormGroup>
      <FormGroup>
      <h3>1. Data Collection</h3>
    <p>Lessonice collects the following data:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">User-provided content such as lessons learned entries.</li>
        <li className="text-primary">System-generated suggestions and analytics.</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>2. Use of Data</h3>
    <p>The data collected is used to:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">Train and improve our AI systems.</li>
        <li className="text-primary">Provide analytics and insights for users.</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>3. Data Security</h3>
    <p>We implement industry-standard measures to protect your data. However, no system is entirely secure, and we cannot guarantee complete data security.</p>
    </FormGroup>
    <FormGroup>
      <h3>4. Third-Party Services</h3>
    <p>We may use third-party services to improve our offerings. These services may have access to user data but are required to comply with our privacy standards.</p>
    </FormGroup>
    <FormGroup>
      <h3>5. User Rights</h3>
    <p>You have the right to:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">Access your data.</li>
        <li className="text-primary">Request deletion of your data (subject to legal and operational constraints).</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>6. Beta Phase Disclaimer</h3>
    <p>During the beta phase:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">Data processing is experimental and may not be fully secure or accurate.</li>
        <li className="text-primary">Users should avoid entering sensitive or confidential information.</li>
    </ul>
    </FormGroup>
    </Card>
  );
};

export default Privacy;
