type User = {
  id: string;
  email: string;
  domain?: string;
  display_name: string;
  role?: Role;
  demo?: boolean;
}

export enum Role {
  member = "Regular Member",
  admin = "Workspace Admin"
}

export default User;