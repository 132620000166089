import React, { useState, useEffect } from 'react';
import { Button, Card, Paper, Grid, FormGroup, Stack, List, ListItem, ListItemText, IconButton, FormLabel } from '@mui/material';
import Api from '../../network/Api';
import { NavLink, useLocation } from 'react-router-dom';
import User from '../../types/User';
import Workspace from '../../types/Workspace';
import JoinableList from './JoinableList';
import WorkspaceItem from './ListItem';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export type WorkspaceSelectionProps = {
  user: User,
  workspaces?: Array<Workspace>,
  joinable?: Array<Workspace>
}

const WorkspaceSelection = (props: WorkspaceSelectionProps) => {
  
  const [workspaces, setWorkspaces] = useState<Array<Workspace> | undefined>();
  const [joinable, setJoinable] = useState<Array<Workspace> | undefined>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    Api.get(`/workspaces${queryParams.get('join_token') ? `?join_token=${queryParams.get('join_token')}` : ''}`).then((result: WorkspaceSelectionProps) => {
      setWorkspaces(result.workspaces);
      setJoinable(result.joinable);
    });
  }, []);

  const setWorkspace = (workspaceId) => {
    Api.put(`/user/workspace`, { workspace_id: workspaceId }).then(result => window.location.replace(result.url));
  }

  const CreateBox = () => {
    return <Card className='bg-dark' elevation={0}>
      <Grid container spacing={2} sx={{alignItems: "center"}}>
        <Grid item xs={12} md={8}>
          <h3 className="text-white text-center text-light">Want to use Lessonice in a different workspace?</h3>
        </Grid>
        <Grid item xs={12} md={4} className='text-center'>
          <Button component={NavLink} 
                  to={`/workspaces/new${queryParams.get('join_token') ? `?join_token=${queryParams.get('join_token')}` : ''}`}
                  variant='outlined' 
                  color='primary' 
                  size="small">Add another workspace</Button>
        </Grid>
      </Grid>
    </Card>
  }

  return <Stack gap={4}>
    { workspaces && workspaces.length > 0 ? <>
      <div className="text-center">
        <h2>Welcome back{ props.user.display_name && `, ${props.user.display_name}` }!</h2>
        <FormLabel>Choose a workspace or create a new one</FormLabel>
      </div>
      
      <Paper>
        <List>
          <ListItem><ListItemText><small>Workspaces for <strong>{ atob(props.user.email) }</strong></small></ListItemText></ListItem>
          {
            workspaces?.map(workspace => <ListItem>
              <WorkspaceItem workspace={workspace} />
              <IconButton sx={{ml: 'auto'}} onClick={() => setWorkspace(workspace.id)}><ArrowForwardIcon /></IconButton>
            </ListItem>)
          }
        </List>
      </Paper>

      { CreateBox() }

      { joinable && joinable.length > 0 && <Stack gap={2} sx={{mt: "1rem"}}>
        <h3>Pending Invitations</h3>
        <JoinableList user={props.user} joinable={joinable} />
      </Stack> }
    </> : <>
      <FormGroup sx={{textAlign: 'center'}}>
        <h2>Welcome to Lessonice</h2>
        <p>You can join any of the workspaces below, or add another one.</p>
      </FormGroup>

      { joinable && joinable.length > 0 && <JoinableList user={props.user} joinable={joinable} /> }
      { CreateBox() }
    </> }
  </Stack>;
};

export default WorkspaceSelection;