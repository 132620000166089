import React, { useState, useEffect } from 'react';
import { Box, Container, Card, Grid, Fab, Stack, FormLabel } from '@mui/material';
import FilledChart from './FilledChart';
import BarChart from './BarChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import Api from '../../../network/Api';
import List from './List';
import { ItemButtonActions } from '../Item';

const Dashboard = (props: ItemButtonActions) => {

  const [values, setValues] = useState();

  useEffect(() => {
    Api.get(`/dashboard`).then(setValues);
  }, []);

  const changeRateLabel = (changeRate: number) => {
    let percent = changeRate * 100;
    return(changeRate != null && <FormLabel>
      <span className={percent >= 0 ? 'text-success' : 'text-error'}>
        {percent >= 0 ? '+' : '-'}{Math.abs(percent).toFixed(1)}% this month
      </span></FormLabel>);
  }

  return <Box sx={{overflowY: 'auto', flexGrow: 1, width: "100%", py: 3}}>
    <Container id="dashboard" maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <Stack direction="row" sx={{placeContent: 'space-between'}}>
              <h3>Lessons learned</h3>
              <Fab disableRipple color="info" size="medium" sx={{cursor: 'default'}}><InventoryIcon /></Fab>
            </Stack>
            <p className='text-lg text-primary'>{typeof(values?.lessons?.total) !== "undefined" ? values.lessons.total : '-'}</p>
            { changeRateLabel(values?.lessons?.change_rate) }
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <h3>SMART sheets</h3>
            <h2 className='text-light'>{typeof(values?.sheets?.completed) !== "undefined" ? values.sheets.completed : '-'} <small className="text-secondary">completed</small></h2>
            <div className='area-chart'>{<FilledChart series={values?.sheets?.series || []} />}</div>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <h3>Team activity</h3>
            <div className='area-chart'>{<BarChart series={values?.history?.series || []} />}</div>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <List onComment={props.onComment} onSheetOpen={props.onSheetOpen} />
          </Card>
        </Grid>
      </Grid>

    </Container>
  </Box>;
};

export default Dashboard;