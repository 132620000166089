import React from 'react';
import { Avatar } from '@mui/material';
import User from '../../types/User';

type AvatarProps = {
  user?: User;
  size: number;
}

const AvatarWrapper = (props: AvatarProps) => {
  const initials = () => {
    if (!props.user?.display_name) { return "" }
    return props.user.display_name.split(" ").slice(0,2).map((name) => name.charAt(0)).join("");
  }
  return(
    <Avatar sx={{width: props.size, height: props.size, fontSize: props.size * 0.4}}>{initials()}</Avatar>
  );
}

export default AvatarWrapper;