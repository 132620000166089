import React from 'react';
import { Modal, Container, Card, Drawer, Stack, IconButton, Breakpoint, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ViewProps } from '../../types/View';

export type ToolboxProps = {
  title?: string,
  maxWidth?: Breakpoint,
  onClose: () => void
}

const Toolbox = (props: ToolboxProps & ViewProps) => {
  return <Modal open onClose={props.onClose}>
    <Container maxWidth={props.maxWidth ?? "md"} sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', outline: 'none'}}>
      <Card component={Stack} spacing={2}>
        <Stack direction="row" sx={{alignItems: 'flex-start', marginBottom: '0.5rem'}}>
          <h3>{props.title}</h3>
          <IconButton sx={{margin: '-8px -8px 0 auto'}} onClick={props.onClose}><CloseIcon /></IconButton>
        </Stack>
        {props.children}
      </Card>
    </Container>
  </Modal>
}

type NavigationDrawerProps = {
  menu: JSX.Element,
  currentContent: JSX.Element,
  sx?: SxProps
}

export const NavigationDrawer = (props: NavigationDrawerProps) => {
  return <Stack direction="row" spacing={0} sx={{height: "100%"}}>
    <Drawer 
      variant="permanent"
      anchor="left"
      sx={{...props.sx, ...{[`& .MuiDrawer-paper`]: { backgroundColor: 'transparent', boxSizing: 'border-box', position: 'relative', borderRadius: "0 !important", paddingLeft: "0 !important", paddingY: "0 !important" }}}}>
      {props.menu}
    </Drawer>
    <Stack sx={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}} spacing={0}>{props.currentContent}</Stack>
  </Stack>
}

export default Toolbox;