import React from 'react';
import { Card, Container, Grid, Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { ViewProps } from '../../types/View';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AdjustIcon from '@mui/icons-material/Adjust';
import CheckIcon from '@mui/icons-material/Check';

type OnboardingLayoutProps = {
  title?: string;
  activeStep?: number;
}

const OnboardingLayout = (props: OnboardingLayoutProps & ViewProps) => {

  const steps = ["Create account", "Set up workspace", "Add context", "Finish setup"];

  function stepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const iconProps = {color: 'var(--color-grey)'};
    return <>{active ? <AdjustIcon sx={iconProps} /> : completed ? <CheckIcon sx={iconProps} /> : <RadioButtonUncheckedIcon sx={iconProps} />}</>
  }

  return <Container component={Card} maxWidth="md">
    <Grid container sx={{width: "auto", margin: "-2rem"}}>
      <Grid item xs={12} sm={4} className="bg-dark" sx={{padding: "1rem 2rem", alignContent: props.title ? "center" : "flex-start"}}>
        { props.title ? <h3 className='text-white'>{props.title}</h3> : <>
        <Stepper activeStep={props.activeStep ?? 0} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel StepIconComponent={stepIcon}>
                {index === (props.activeStep ?? 0) ? <strong>{step}</strong> : <>{step}</>}
              </StepLabel>
            </Step>
          ))}
        </Stepper> </> }
      </Grid>
      <Grid item xs={12} sm={8} sx={{padding: "2rem"}}>
        {props.children}
      </Grid>
    </Grid>
  </Container>
};

export default OnboardingLayout;