import React from "react";
import { FormLabel, Grid, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MultilineTextField } from "../Shared/FormItems";
import SplitRow from "./SplitRow";
import { ChangeProps } from "./Sheet";
import Api from "../../network/Api";

const OwnerFields = (props: ChangeProps) => {

  enum Tooltip {
    Specific = "Make your results clear. It should not confuse but remove uncertainty. Give a clear instruction to the accountable editor of this lesson learned. How can you get to the heart of what you want to achieve? What do you want to achieve if this topic is resolved?",
    Measurable = "Quantify your aims with objective markers so it will be clearer when you've completed it e.g. if this topic never pops-up again, we expect the quality/output to increase by 15%.",
    Achievable = "Find the middle ground between challenging and impossible. While a simple and obvious answer to this lesson learned will not bring any value, neither writing a book does. Ask for something that brings the reader of this lessons learned on the right path to resolution and not making the same mistake again.",
    Relevant = "Give the mitigation a higher meaning. Align with your company's overall mission/vision, OKRs, annual targets, or corporate strategy. What would it mean for your organization if this issue never occurs again?",
    Timebound = "Thinks tend to take as much time as you give them. Be realistic when you set the deadline to accomplish this task. Typically, 1 week is choosen as a time frame.",
  }

  const suggest = (type: string, hint: string, completion: (result: string) => void) => {
    Api.post(`/sheets/${props.sheet.id}/suggest`, { type: type, hint: hint }).then(result => result[type] && completion(result[type]));
  }
  
  return <>
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{mb: 3}}>
        <FormLabel>Incorporating the SMART approach ensures that the lessons learned are not only insightful but also actionable and aligned with the company's overall objectives.</FormLabel>
      </Grid>

      <SplitRow label="S(pecific)" tooltip={Tooltip.Specific}>
        { props.disabled ? <ListItemText>{props.sheet.specific}</ListItemText> : (
        <MultilineTextField
          name="specific"
          placeholder="Define a specific goal"
          value={props.params?.specific ?? ""}
          onChange={value => props.onChange({...props.params, specific: value})}
          onSave={value => props.onSave({specific: value})}
          onAiSuggest={() => {
            suggest("specific", Tooltip.Specific, result => {
              props.onChange({...props.params, specific: result});
              props.onSave({ specific: result });
            });
          }}
        /> )}
      </SplitRow>

      <SplitRow label="M(easurable)" tooltip={Tooltip.Measurable}>
        { props.disabled ? <ListItemText>{props.sheet.measurable}</ListItemText> : (
        <MultilineTextField
          name="measurable"
          placeholder="Define a measurable goal"
          value={props.params?.measurable ?? ""}
          onChange={value => props.onChange({...props.params, measurable: value})}
          onSave={value => props.onSave({measurable: value})}
          onAiSuggest={() => {
            suggest("measurable", Tooltip.Measurable, result => {
              props.onChange({...props.params, measurable: result});
              props.onSave({ measurable: result });
            });
          }}
        /> )}
      </SplitRow>

      <SplitRow label="A(chievable)" tooltip={Tooltip.Achievable}>
        { props.disabled ? <ListItemText>{props.sheet.achievable}</ListItemText> : (
        <MultilineTextField
          name="achievable"
          placeholder="Define an achievable goal"
          value={props.params?.achievable ?? ""}
          onChange={value => props.onChange({...props.params, achievable: value})}
          onSave={value => props.onSave({achievable: value})}
          onAiSuggest={() => {
            suggest("achievable", Tooltip.Achievable, result => {
              props.onChange({...props.params, achievable: result});
              props.onSave({ achievable: result });
            });
          }}
        /> )}
      </SplitRow>

      <SplitRow label="R(elevant)" tooltip={Tooltip.Relevant}>
        { props.disabled ? <ListItemText>{props.sheet.relevant}</ListItemText> : (
        <MultilineTextField
          name="relevant"
          placeholder="Define a relevant goal"
          value={props.params?.relevant ?? ""}
          onChange={value => props.onChange({...props.params, relevant: value})}
          onSave={value => props.onSave({relevant: value})}
          onAiSuggest={() => {
            suggest("relevant", Tooltip.Relevant, result => {
              props.onChange({...props.params, relevant: result});
              props.onSave({ relevant: result });
            });
          }}
        /> )}
      </SplitRow>

      <SplitRow label="T(ime-bound)" tooltip={Tooltip.Timebound}> 
        { props.disabled ? <ListItemText>{props.sheet.timebound ? dayjs.unix(props.sheet.timebound).format('ll') : '-'}</ListItemText> : (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <DatePicker
            value={props.params?.timebound ? dayjs.unix(props.params?.timebound) : dayjs()}
            minDate={dayjs()}
            slotProps={{ textField: { variant: 'filled' } }}
            onChange={(newValue) => {
              props.onChange({...props.params, timebound: newValue?.unix()});
              props.onSave({timebound: newValue?.unix()})
            }}
          />
        </LocalizationProvider>
        )}
      </SplitRow>
    </Grid>
  </>;
};

export default OwnerFields;
