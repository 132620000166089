import React from "react";
import {
  FormLabel,
  Grid,
  Tooltip,
} from "@mui/material";
import { ViewProps } from "../../types/View";
import InfoIcon from '@mui/icons-material/Info';

type RowItemProps = {
  label?: string;
  tooltip?: string;
};

const SplitRow = (props: RowItemProps & ViewProps) => {
  return (
    <>
      <Grid item xs={12} sm={3}>
        <FormLabel className="text-primary">
          {props.label}
          {props.tooltip && <Tooltip title={props.tooltip} sx={{ml: 0.5}}><InfoIcon color="secondary" fontSize="small" /></Tooltip>}
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        {props.children}
      </Grid>
    </>
  );
};

export default SplitRow;