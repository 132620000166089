import React from 'react';
import { NavLink } from 'react-router-dom';
import LessoniceLogo from 'images/logo.png';

const Logo = (props) => {

  const logoBox = () => {
    return <div className={`logo-label text-${props.color}`} style={{cursor: props.to ? "pointer" : "default"}}>{props.icon && <img src={LessoniceLogo} />} Lessonice</div>
  }

  return props.to ? <NavLink to={props.to} reloadDocument>{logoBox()}</NavLink> : logoBox()
}

export default Logo;