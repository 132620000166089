import { ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

const ConsentScripts = (props) => {

  const [allowed, setAllowed] = useState(getCookieConsentValue());

  useEffect(() => {
    
    if (allowed !== 'true') { return }

    window.hsConversationsSettings = {
      loadImmediately: false,
    }
    addScript("https://www.googletagmanager.com/gtag/js?id=G-P6G03H5W1T");
    
    gtag('js', new Date());
    gtag('config', 'G-P6G03H5W1T');
  });

  const addScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  return (<CookieConsent
            location="bottom"
            buttonText="Allow"
            ariaAcceptLabel="Allow"
            enableDeclineButton={true}
            declineButtonText="Deny"
            ariaDeclineLabel="Deny"
            onAccept={() => setAllowed('true')}
            onDecline={() => setAllowed('false')}
          >
            <ListItemText><strong>This website uses cookies</strong></ListItemText>
            <ListItemText>We use cookies and similar technologies to maintain the functionality of the application. You consent to the use of such technologies by clicking Agree, by clicking any link or button outside of this notice, or by continuing to use the Application in any other way.</ListItemText>
          </CookieConsent>
  );
}

export default ConsentScripts;