import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, Chip, TextField, FormGroup, Stack, Tooltip, FormLabel, ListItemText } from '@mui/material';
import { ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';
import Api from '../../network/Api';
import moment from 'moment';
import OrDivider from "../Shared/OrDivider";

import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import { LoadingButton } from '@mui/lab';

type Link = {
  url: string,
  expires_at: number
}

const Invite = (props: StartProps & ToolboxProps) => {

  const [emails, setEmails] = useState<Array<string>>([]);
  const [link, setLink] = useState<Link>();
  const [isSending, shouldSend] = useState<boolean>(false);
  const [isCopying, shouldCopy] = useState<boolean>(false);

  useEffect(() => {
    if (!isSending || emails.length <= 0) { return }
    Api.post("/workspace/invitations", {emails: emails}).then(result => {
      window.location.replace(result.url)
    })
  }, [isSending]);

  const copyLink = () => {
    shouldCopy(false);
    if (typeof(link?.url) === "undefined") { return }
    navigator.clipboard.writeText(link.url);
  }

  useEffect(() => {
    if (!isCopying) { return }
    if (typeof(link) !== "undefined") {
      copyLink();
      return
    }
    Api.post("/workspace/invitations", {linkonly: true}).then(setLink);
  }, [isCopying]);

  useEffect(() => {
    copyLink();
  }, [link]);

  return <>
    <Stack spacing={1}>
      <FormLabel>Send to</FormLabel>
        
      <Autocomplete
          multiple
          options={[]}
          value={emails}
          freeSolo
          disableClearable
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip {...getTagProps({ index })} label={option} />
            ))
          }
          onChange={(e, value) => setEmails(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              type="email"
              placeholder="Add one or more email addresses, seperated by enter key"
            />
          )}
        />

      <LoadingButton
        variant="outlined" 
        color="primary"
        startIcon={<SendIcon />}
        loading={isSending}
        disabled={emails.length <= 0}
        onClick={() => shouldSend(true)}>Send</LoadingButton>

      {props.workspace.domain && (
        <FormGroup>
          <FormLabel className="text-center"><small>Only people with a <strong>@{props.workspace.domain}</strong> email will be able to join your workspace.</small></FormLabel>
        </FormGroup>
      )}
    </Stack>

    <OrDivider />

    <FormGroup>
      <Stack direction="row" spacing={1}>
        <FormLabel sx={{flex: 3}}>Share an expiring link with people in your company.</FormLabel>
        <Tooltip 
          title={`Link copied - expires ${moment.unix(link?.expires_at).fromNow()}.`} 
          open={typeof(link) !== "undefined"}
          sx={{flex: 1}}>
          <LoadingButton
            variant='text'
            size='small'
            startIcon={<ShareIcon />}
            onClick={() => shouldCopy(true)}
            loading={isCopying}>Copy link</LoadingButton>
        </Tooltip>
      </Stack>
    </FormGroup>
  </>
}

export default Invite;