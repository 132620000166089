import React from 'react';
import { Box, Button, Dialog, FormGroup, Stack } from '@mui/material';

type DialogProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog = (props: DialogProps) => {
  return (
    <Dialog
      open
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{p: 3}}>
        <FormGroup>
          <h3 id="alert-dialog-title">
            {props.title}
          </h3>
          <p id="alert-dialog-description">
            {props.message}
          </p>
        </FormGroup>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="text" color="secondary" size="small" onClick={props.onCancel}>Cancel</Button>
          <Button variant="outlined" size="small" color="primary" onClick={props.onConfirm} autoFocus>Confirm</Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default ConfirmDialog;