import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, FormGroup, FormLabel, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Api from '../../network/Api';

const VerificationForm = () => {

  const { verificationId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [email, setEmail] = useState<string>();
  const [otp, setOtp] = useState<string>('');
  const [isLoading, shouldLoad] = useState(false);

  useEffect(() => {
    Api.get(`/verifications/${verificationId}`).then(result => setEmail(atob(result.email)));
  }, []);

  useEffect(() => {
    if (isLoading) {
      Api.put(`/verifications/${verificationId}`, { code: otp, redirect_url: queryParams.get('redirect_url') }).then(result => window.location.replace(result.url));
    }
  }, [isLoading]);

  const resend = () => {
    Api.post(`/verifications`, { email: email, redirect_url: queryParams.get('redirect_url') }).then(result => window.location.replace(result.url));
  }

  useEffect(() => {
    if (otp.length === 6) {
      shouldLoad(true);
    }
  }, [otp]);

  return <Card>
    <Grid container sx={{alignItems: 'center', justifyContent: 'space-around'}}>
      <Grid item xs={12} md={5}>
        <Stack sx={{textAlign: 'center', gap: 2, mb: "1rem"}}>
          <h3>Check your email for a code</h3>
          <FormLabel>We've sent a 6-character code to <strong>{email}</strong></FormLabel>
        </Stack>
      </Grid>

      <Grid item xs={12} md={5}>
        <FormGroup>
          <MuiOtpInput length={6} value={otp} onChange={(newValue) => setOtp(newValue)} />
        </FormGroup>

        <FormGroup>
          <LoadingButton variant="outlined" 
                        onClick={() => shouldLoad(true)}
                        loading={isLoading}>Verify</LoadingButton>
        </FormGroup>

        <p className='text-center'>
          <FormLabel className='text-center'>No code received? You may check your SPAM folder or <a href="#" onClick={resend}><strong>Resend code</strong></a></FormLabel>
        </p>
      
      </Grid>
    </Grid>
  </Card>
};

export default VerificationForm;