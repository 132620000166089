import User from "./User";

type Sheet = {
  id: string;
  url: string;
  state: SheetState;
  created_at: number;
  updated_at: number;
  owner?: User;
  accountable?: User;
  approver?: User;
  specific: string;
  measurable: string;
  achievable: string;
  relevant: string;
  timebound: number;
  mitigation?: string;
};

export enum SheetState {
  new,
  draft,
  review,
  approved,
  rejected
}

export default Sheet;
