import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Stack } from '@mui/material';
import NewLesson from "./Add/New";

import { NavigationDrawer } from "../Shared/Toolbox";
import Workspace from "../../types/Workspace";
import Dashboard from "./Dashboard/Dashboard";

import PostAddIcon from '@mui/icons-material/PostAdd';
import HistoryIcon from '@mui/icons-material/History';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import Lesson from "../../types/Lessons";
import User from "../../types/User";
import TimelineActions from "../Timeline/Actions";
import Api from "../../network/Api";
import { TimelineAction } from "../../types/Timeline";
import Chat from "./Chat";

export type StartProps = {
  user: User,
  workspace: Workspace
}
type SelectionProps = {
  title: string,
  showsDraft: boolean
}

const Start = (props: StartProps) => {

  const [draftLessons, setDraftLessons] = useState<Array<Lesson>>();
  const [history, setHistory] = useState<Array<TimelineAction>>([]);
  const [endReached, setEndReached] = useState<boolean>(false);
  const [isLoading, shouldLoad] = useState<boolean>(false);

  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Api.get('/lessons/new').then(setDraftLessons);
    shouldLoad(true);
  }, []);

  useEffect(() => {
    if (!isLoading) { return }
    Api.get(`/timeline${history.length > 0 ? `?after=${history[history.length - 1].timestamp}` : ''}`).then(result => {
      shouldLoad(false);
      setHistory(current => {
        let newList = [...current, ...result.items];
        setEndReached(newList.length >= result.total);
        return newList;
      });
    });
  }, [isLoading]);

  const createSheet = (lesson: Lesson) => {  
    if (typeof lesson.sheet !== "undefined" && lesson.sheet.id) {
      navigate(`/sheets/${lesson.sheet.id}`);
    } else {
      Api.post(`/lessons/${lesson.id}/sheets`, {}).then((result) => {
        if (!result?.id) {
          return;
        }
        navigate(`/sheets/${result.id}`);
      });
    }
  };

  const renderSelectedContent = () => {
    switch(pathname) {
      case "/lessons":
        return <NewLesson defaultValue={hash === "#draft" && draftLessons ? draftLessons : undefined}
                  onCompletion={() => {
                    setDraftLessons(undefined);
                    setHistory([]);
                    shouldLoad(true);
                  }} />
      case "/chat":
        return <Chat onComment={createSheet} onSheetOpen={createSheet} />
      case "/dashboard":
        return <Dashboard onComment={createSheet} onSheetOpen={createSheet} />
      default:
        return <Selection 
                  {...props}
                  title={hash === "#completed" ? "Congratulations - you have completed the task!<br />What do you want to do next?" : "What do you want to do today?"}
                  showsDraft={typeof(draftLessons) !== "undefined" && draftLessons.length > 0} />
    }
  }

  return <NavigationDrawer 
      menu={<TimelineActions {...props}
                history={history}
                isLoading={isLoading}
                endReached={endReached}
                onLoad={() => shouldLoad(true)}
                onComment={createSheet} 
                onSheetOpen={createSheet} />} 
      currentContent={renderSelectedContent()}
      sx={{display: {xs: 'none', lg: 'flex'}}} />;
}

const Selection = (props: SelectionProps & StartProps) => {

  const [knowledgeGained, setKnowledgeGained] = useState<boolean>(props.workspace.knowledge_gained);

  return <Container maxWidth="md"><Stack spacing={3}>
    <h2 style={{textAlign: 'center'}} 
      className='animate__animated animate__fadeInUp'
      dangerouslySetInnerHTML={{__html: props.title }}>
    </h2>

    <Stack direction={{xs: "column", sm: "row"}} gap={2} sx={{justifyContent: 'center'}}>

    { props.showsDraft && <Button variant="text"
      className="animate__animated animate__fadeInUp animate__delay-2s"
      color="warning"
      size="medium"
      startIcon={<HistoryIcon />}
      component={NavLink}
      to="/lessons#draft">Continue Draft</Button> }

      <Button variant="outlined" 
              color="secondary"
              size="medium"
              startIcon={<PostAddIcon />}
              component={NavLink}
              className="animate__animated animate__fadeInUp animate__delay-2s"
              to="/lessons">Add lessons learned</Button>

      <Button variant="outlined" 
              disabled={!knowledgeGained}
              color="secondary"
              size="medium"
              startIcon={<AutoAwesomeIcon />}
              component={NavLink}
              className="animate__animated animate__fadeInUp animate__delay-2s"
              to="/chat">Query knowledge</Button>
    </Stack>

  </Stack></Container>
}

export default Start;