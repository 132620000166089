import Lesson from "./Lessons";
import Sheet from "./Sheet";
import User from "./User";

export type TimelineAction = {
  id: string;
  timestamp: number;
  action_type: TimelineActionType;
  user: User;
  lesson?: Lesson;
  sheet?: Sheet;
};

export enum TimelineActionType {
  workspace_created,
  lesson_created,
  workspace_joined,
  sheet_created,
  sheet_approved
}
