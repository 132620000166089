import React from 'react';
import { NavLink } from "react-router-dom";
import { Menu, MenuItem, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Stack, Chip } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import User, { Role } from '../../types/User';
import Workspace from '../../types/Workspace';
import Avatar from './Avatar';
import TuneIcon from '@mui/icons-material/Tune';

type UserMenuProps = {
  user: User,
  workspace: Workspace,
  anchorEl: Element | (() => Element),
  open: boolean,
  onClose: () => void
}

const UserMenu = (props: UserMenuProps) => {
  return(
    <Menu
      id="user-menu"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      <ListItem>
        <ListItemText>
          <Stack sx={{alignItems: "center"}} spacing={1}>
            <Avatar size={60} user={props.user} />
            <h3><small>{props.user.display_name}</small></h3>
            <small style={{margin: 0}} className="text-secondary">{atob(props.user.email)}</small>
            <Chip icon={<Diversity3Icon fontSize='small' />} label={props.workspace.name} />
          </Stack>
        </ListItemText>
      </ListItem>

      { Role[props.user.role] === Role.admin && <>
        <Divider />

        <MenuItem component={NavLink} to="#workspace" onClick={props.onClose}>
          <ListItemIcon><TuneIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Workspace settings</ListItemText>
        </MenuItem>
      </> }

      <Divider />
      
      <MenuItem component={NavLink} to="#user" onClick={props.onClose}>
        <ListItemIcon><SettingsOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Account settings</ListItemText>
      </MenuItem>

      { props.workspace.switchable && <MenuItem component={NavLink} to="/workspaces" onClick={props.onClose}>
        <ListItemIcon><SyncIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Switch workspace</ListItemText>
      </MenuItem> }
      
      <MenuItem component="a" href="/logout">
        <ListItemIcon><LogoutOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Sign out</ListItemText>
      </MenuItem>
    </Menu>
  );
}
export default UserMenu;