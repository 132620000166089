import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Card, Chip, Stack, Button, FormLabel, ListItemText, ListItem, List, Box } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import StarIcon from '@mui/icons-material/Star';

type ProductProps = {
  name: string;
  label: string;
  badge?: JSX.Element;
  priceInfo: string;
  price: string;
  interval?: string;
  cta: string;
  features: Array<string>;
  url: string;
};

const PricingBoxes = (props) => {
  return (
    <>
      <Grid container spacing={4} sx={{ mb: "1rem" }}>
        <Grid item xs={12} md={4}>
          <PricingBox
            name="Free"
            label="All our premium features. For free."
            priceInfo="No credit card required."
            price={"$ 0"}
            interval="month"
            cta="Try now"
            features={[
              "Store unlimited lessons learned",
              "Up to 5 active users per month",
              "Up to 1,000 requests",
            ]}
            url="/signup"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <PricingBox
            name="Business"
            badge={<FormLabel><StarIcon fontSize="small" /> Recommended</FormLabel>}
            label="The best choice for high performing workspaces."
            priceInfo="Starting from"
            price={"$ 49.90"}
            interval="month"
            cta="Get started"
            features={[
              "Customer support",
              "Individual team size",
              "Unlimited requests",
            ]}
            url="/signup"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <PricingBox
            name="Enterprise"
            label="Enterprise-level power. Talk to us to build your plan."
            priceInfo="Ask us for"
            price={"Individual"}
            cta="Talk to sales"
            features={[
              "Customizable plans",
              "Enterprise controls",
              "Access enterprise resources",
            ]}
            url="/signup"
          />
        </Grid>
      </Grid>

      <p className="text-center"><FormLabel><small>Prices do not include applicable taxes.</small></FormLabel></p>

    </>
  );
};

const PricingBox = (props: ProductProps) => {
  return (
    <Card>
      <Stack spacing={2}>
        <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
          <h2>{props.name}</h2>
          {props.badge && <Chip label={props.badge} />}
        </Stack>
        <p>{props.label}</p>
        <FormLabel sx={{pt: 2}}>{props.priceInfo}</FormLabel>
        <h2>
          <span className="text-lg">{props.price}</span>
          {props.interval && (
            <>
              {" "}
              <small className="text-secondary">/{props.interval}</small>
            </>
          )}
        </h2>

        <Box sx={{pt: 1}}>
          <Button
            variant="outlined"
            color={props.badge ? "primary" : "secondary"}
            endIcon={<ArrowRightAltIcon />}
            component={NavLink}
            to={props.url}
          >
            {props.cta}
          </Button>
        </Box>

        <List>
          {props.features.map((feature) => <ListItem>
            <ListItemText>
              <TaskAltIcon fontSize="small" color="secondary" sx={{verticalAlign: "-0.25rem" }} /> <small>{feature}</small>
            </ListItemText>
          </ListItem>)}
        </List>
      </Stack>
    </Card>
  );
};

export default PricingBoxes;
