import React from "react";
import { Alert, Box, Button, Divider, Icon, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";
import Lesson from "../../types/Lessons";
import { SheetState } from "../../types/Sheet";

import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import MoreIcon from "@mui/icons-material/More";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Linkify from "linkify-react";

export type ItemButtonActions = {
  onSheetOpen?: (lesson: Lesson) => void;
  onComment?: (lesson: Lesson) => void;
}

type ItemButtonProps = {
  lesson: Lesson;
  condensed?: boolean;
  size?: "small" | "medium";
  direction?: "row" | "column";
  disablePadding?: boolean;
}

export const SheetButton = (props: ItemButtonProps & ItemButtonActions) => {
  const currentIcon = (size: "small" | "medium") => {
    switch (SheetState[props.lesson.sheet?.state]) {
      case SheetState.review:
        return <HourglassBottomIcon color="warning" fontSize={size} />;
      case SheetState.approved:
        return <BookmarkAddedIcon color="success" fontSize={size} />;
      case SheetState.rejected:
        return <DoDisturbIcon color="error" fontSize={size} />;
      default:
        return <MoreIcon color="secondary" fontSize={size} />;
    }
  };

  return <IconButton
    size={props.size}
    onClick={() => props.onSheetOpen && props.onSheetOpen(props.lesson)}
    sx={{ fontSize: "smaller" }}
  >
    {currentIcon(props.size)}
  </IconButton>
}

export const CommentButton = (props: ItemButtonProps & ItemButtonActions) => {
  return (
    <IconButton
      onClick={() => props.onComment && props.onComment(props.lesson)}
      sx={{ fontSize: "small" }}
      size={props.size}
    >
      <MarkChatUnreadIcon color="secondary" fontSize={props.size} sx={{mr: "0.2rem"}} />
      <strong>{props.lesson.comments}</strong>
    </IconButton>
  )
}

const LessonItem = (props: ItemButtonProps & ItemButtonActions) => {
  return <>
    <ListItem
      className="animate__animated animate__fadeIn"
      key={props.lesson.id}
      disablePadding={props.disablePadding}
    >
      <Stack direction="row" justifyContent="space-between" flexGrow={1}>
        <ListItemText>
          <Stack spacing={1}>
            {props.size === "small" ? <small>{props.lesson.learned}</small> : <>{props.lesson.learned}</>}
            {props.lesson.sheet?.mitigation && <Alert><small><Linkify options={{target: "_blank"}}>{props.lesson.sheet.mitigation}</Linkify></small></Alert>}
          </Stack>
        </ListItemText>
        <Stack direction={props.direction}>
          <Box><SheetButton lesson={props.lesson} onSheetOpen={props.onSheetOpen} size="small" /></Box>
          <Box><CommentButton lesson={props.lesson} onComment={props.onComment} size="small" /></Box>
        </Stack>
      </Stack>
    </ListItem>
  </>;
};

export default LessonItem;
