import React from 'react';
import { AppBar, Container, Chip, Stack, Button, Link } from '@mui/material';
import Logo from '../Shared/Logo';
import { ViewProps } from '../../types/View';
import { AppProps } from '../App';

const Layout = (props: AppProps & ViewProps) => {
  return <>
    <AppBar position="relative" sx={{ alignItems: 'center', padding: '3rem 0 0'}}>
      <Logo color="primary" to="/" />
    </AppBar>
    <Container 
      maxWidth="md"
      sx={{marginBottom: '5rem', padding: props.user ? "1rem" : "3rem 1rem"}}>
        
      { props.user && <div className='text-center' style={{marginBottom: "3rem"}}>
        <Chip color="secondary" label={<Stack direction="row" gap={1}>
          <span className='text-secondary'>Confirmed as { atob(props.user.email) }</span>
          <Link onClick={() => window.location.replace("/logout")}>Change</Link>
        </Stack>} />
      </div> }

      {props.children}
    </Container>        
  </>;
}

export default Layout;