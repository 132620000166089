import React, { useState, useEffect, useRef } from "react";
import { Button, Stack, Box, Container, OutlinedInput, Paper, Card, List, ListItem, ListItemText } from "@mui/material";
import Api from "../../network/Api";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ScrollControl from "../Shared/ScrollControl";
import Lesson from "../../types/Lessons";
import TypingIndicator from "../Shared/TypingIndicator";
import LessonItem, { ItemButtonActions } from "./Item";
import Typewriter from 'typewriter-effect';

type ChatItem = {
  prompt?: string;
  response?: string;
  lessons?: Array<Lesson>;
}

const Chat = (props: ItemButtonActions) => {

  const [history, setHistory] = useState<Array<ChatItem>>([]);
  const [prompt, setPrompt] = useState<string>("");
  const [isAsking, shouldAsk] = useState<boolean>(false);

  const scrollTopRef = useRef();
  const scrollBottomRef = useRef();
  const [refsSet, setRefsSet] = useState(false);

  useEffect(() => {
    if (scrollTopRef.current && scrollBottomRef.current) {
      setRefsSet(true);
    }
  }, [scrollTopRef.current, scrollBottomRef.current]);

  useEffect(() => {
    setPrompt("");
    if (!isAsking) { return }
    setHistory(current => { return [...current, { prompt: prompt }]})
    Api.post("/chat", { prompt: prompt }).then(result => {
      shouldAsk(false);
      if (!result?.response && !result?.lessons) { return }
      setHistory(current => { return [...current, result]});
    })
  }, [isAsking]);

  useEffect(() => {
    if (scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAsking]);

  const renderBubble = (item: ChatItem) => {
    if (typeof(item.prompt) !== "undefined") {
      return <Stack direction="row" justifyContent="flex-end"><Card elevation={0} className="bg-grey" sx={{maxWidth: '75%', padding: "0.75rem 1rem !important"}}><p>{item.prompt}</p></Card></Stack>
    }
    return <Stack spacing={2}>
      <p>{item.response}</p>
      { item.lessons && item.lessons.length > 0 && (
        <Paper sx={{mx: "2rem !important"}}>
          <List sx={{p: 2}}>
            {item.lessons.map(lesson => <LessonItem lesson={lesson} onComment={props.onComment} onSheetOpen={props.onSheetOpen} size="small" direction="row" />) }
          </List>
        </Paper>
      )}
    </Stack>
  }

  return (
    <Stack
      className="animate__animated animate__fadeIn"
      marginY={"auto"}
      flexGrow={history.length > 0 ? 1 : 0}
      width="100%"
    >
      <Stack
        sx={{
          flex: history.length > 0 ? "1 1 0" : 1,
          overflowY: "auto",
          pt: 4,
          pb: 3,
          alignItems: "center",
        }}
      >
        <Container maxWidth="md" sx={{pb: (history.length > 0 ? "5rem" : 1)}}>
          <Box ref={scrollTopRef} />
          <Stack spacing={3}>
            <h3>
              How can I help you today?
              { !prompt && !isAsking && history.length <= 0 && <>
              <br />e.g. <Typewriter
                options={{
                  strings: [
                    'What are my lessons learned?',
                    'Show me trending lessons learned',
                    'What went well or wrong recently?',
                    'What a are common mistakes when ...',
                    'What is usually going wrong with ...',
                    'Why do we always do ... instead of ...',
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 0.5
                }}
              /></> }
            </h3>
      
            { history.map(item => <>{renderBubble(item)}</>)}
            { isAsking && <TypingIndicator /> }
          </Stack>
          <Box ref={scrollBottomRef} />
        </Container>
      </Stack>

      { refsSet && <ScrollControl scrollTopRef={scrollTopRef} scrollBottomRef={scrollBottomRef} /> }

      <Container maxWidth="md" sx={{mb: 3}}>
        <OutlinedInput
          placeholder="Message Lessonice"
          fullWidth
          sx={{ flex: 1, pr: 0, pl: 1 }}
          autoComplete="off"
          name="ask"
          endAdornment={
            <Button
              size="small"
              sx={{ ml: "0.5rem" }}
              onClick={() => {
                if (prompt === "") {
                  return;
                }
                shouldAsk(true);
              }}
            >
              <AutoAwesomeIcon />
            </Button>
          }
          value={prompt}
          onChange={(e) => setPrompt(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (prompt === "" || e.key !== "Enter") {
              return;
            }
            shouldAsk(true);
          }}
        />
      </Container>
    </Stack>
  );
};

export default Chat;
