import React, { useState } from "react";
import UserNav from "../Shared/UserNav";
import { Box, Snackbar } from "@mui/material";
import InfoLayout from "./InfoLayout";
import ConsentScripts from "../../utils/ConsentScripts";
import { LayoutProps, ViewProps } from "../../types/View";
import { AppProps } from "../App";
import Toolbox from "../Shared/Toolbox";
import Settings from "../User/Settings";
import WorkspaceSettings from "../Workspaces/Settings";
import Invite from "../Invitations/Invite";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../network/Api";

const Layout = (props: AppProps & LayoutProps & ViewProps) => {
  const [flash, setFlash] = useState(props.flash);
  const navigate = useNavigate();
  const { hash } = useLocation();

  const switchDemo = (on: boolean) => {
    Api.put("/workspace", { demo: on }).then(result => window.location.reload());
  }

  return <>
    <ConsentScripts />

    {/* {flash
      ?.filter((flash) => flash.severity == "event")
      ?.forEach((event) => {
        //      gtag('event', event.message.name);
      })} */}

    {flash
      ?.filter((flash) => flash.severity != "event")
      .map((flash) => {
        return (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            onClose={() => setFlash([])}
            message={flash.message}
            key={flash.message}
            autoHideDuration={10000}
          />
        );
      })}

    {props.variant ? (
      props.variant === "info" ? (
        <InfoLayout {...props} />
      ) : (
        <Box display="flex" flexDirection="column" height="100vh">
          <UserNav {...props} onSwitchDemo={switchDemo} />
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            overflow="scroll"
            zIndex={0}
          >
            {props.children}
          </Box>
        </Box>
      )
    ) : (
      props.children
    )}

  { props.user && props.workspace && <>
    { hash.startsWith('#user') && <Toolbox title="Account settings" onClose={() => navigate('#')}><Settings {...props} onClose={() => navigate('#')} /></Toolbox> }
    { hash.startsWith('#workspace') && <Toolbox title="Workspace settings" onClose={() => navigate('#')}><WorkspaceSettings {...props} onSwitchDemo={switchDemo} onClose={() => navigate('#')} /></Toolbox> }
    { hash.startsWith('#invite') && <Toolbox title={`Invite team to Lessonice`} maxWidth="sm" onClose={() => navigate('#')}><Invite {...props} onClose={() => navigate('#')} /></Toolbox> }
    </> }
  </>
};

export default Layout;
