import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Skeleton, Stack, FormLabel, ListItemText, Paper, IconButton, Tooltip, Collapse, Alert } from "@mui/material";
import Api from "../../network/Api";
import Lesson from "../../types/Lessons";
import Sheet from "../../types/Sheet";
import User from "../../types/User";
import SplitRow from "./SplitRow";
import Toolbar from "./Toolbar";
import { SheetState } from "../../types/Sheet";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import OwnerFields from "./OwnerFields";
import { MultilineTextField } from "../Shared/FormItems";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Linkify from "linkify-react";

type SheetParams = {
  specific?: string;
  measurable?: string;
  achievable?: string;
  relevant?: string;
  timebound?: number;
  mitigation?: string;
  accountable?: User | string;
  approver?: User | string;
};

type SimilarItem = {
  id: string;
  learned: string;
}

export type ChangeProps = {
  sheet: Sheet;
  params?: SheetParams;
  disabled?: boolean;
  onChange: (object: Object) => void;
  onSave: (object: Object) => void;
}

const EditSheet = (user: User) => {
  const { sheetId } = useParams();
  const [lesson, setLesson] = useState<Lesson>();
  const [sheet, setSheet] = useState<Sheet>();
  const [similarTo, setSimilarTo] = useState<Array<SimilarItem>>();
  const [users, setUsers] = useState<Array<User>>([]);
  const [params, setParams] = useState<SheetParams>({});
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showsOwnerSection, showOwnerSection] = useState<boolean>(false);
  const [showsAccountableSection, showAccountableSection] = useState<boolean>(false);

  useEffect(() => {
    loadSheet();
  }, []);

  useEffect(() => {
    if (typeof(sheet) === "undefined") { return }
    setParams({...params, ...sheet});
  }, [sheet]);

  const loadSheet = () => {
    Api.get(`/sheets/${sheetId}`).then(setSheetResults);
  }

  const unlink = (item: SimilarItem) => {
    Api.put(`/lessons/${item.id}`, { similar: null }).then(result => loadSheet());
  }

  const save = (params: SheetParams) => {
    setIsUpdating(true);
    Api.put(`/sheets/${sheetId}`, params).then(setSheetResults);
  }

  const assign = () => {
    setIsUpdating(true);
    Api.put(`/sheets/${sheetId}`, {state: SheetState[SheetState.draft] }).then(setSheetResults);
  }
  const approve = () => {
    setIsUpdating(true);
    Api.put(`/sheets/${sheetId}`, {state: SheetState[SheetState.review] }).then(setSheetResults);
  }
  const accept = () => {
    setIsUpdating(true);
    Api.put(`/sheets/${sheetId}`, {state: SheetState[SheetState.approved] }).then(setSheetResults);
  }
  const reject = (comment?: string) => {
    setIsUpdating(true);
    if (comment && lesson) {
      Api.post(`/lessons/${lesson.id}/comments`, { content: comment, color: 'error' });
    }
    Api.put(`/sheets/${sheetId}`, {state: SheetState[SheetState.rejected] }).then(result => window.location.reload());
  }
  const reopen = () => {
    setIsUpdating(true);
    Api.put(`/sheets/${sheetId}`, {state: SheetState[SheetState.new] }).then(result => window.location.reload());
  }

  const setSheetResults = (result) => {
    setLesson(result.lesson);
    setSheet(result.sheet);
    setSimilarTo(result.similar);
    setUsers(result.users);
    setIsUpdating(false);
    showOwnerSection(result.sheet && [SheetState.new].includes(SheetState[result.sheet.state]))
    showAccountableSection(result.sheet && [SheetState.draft, SheetState.review, SheetState.rejected].includes(SheetState[result.sheet.state]))
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>        
        <Grid item xs={12} md={7}>
          <Stack spacing={3} sx={{mt: 2, mb: 4}}>
            <Paper sx={{padding: 4}}>
              {lesson && sheet ? (
                <Grid container spacing={2}>
                  <SplitRow label="Lesson learned">
                    <ListItemText sx={{m: 0}}><strong>{lesson.learned}</strong></ListItemText>
                  </SplitRow>

                  { similarTo && similarTo.length > 0 && <SplitRow label="Also mentioned">
                    <>{similarTo?.map(similar => {
                      return (
                        <ListItemText key={similar.id}>
                          <FormLabel>{similar.learned} {sheet.owner?.id === user.id && (
                            <Tooltip title="Unlink">
                              <IconButton size="small" color="secondary" onClick={() => unlink(similar)}>
                                <LinkOffIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}</FormLabel>
                        </ListItemText>
                      )})}</>
                  </SplitRow> }

                  { sheet.mitigation && [SheetState.review, SheetState.approved, SheetState.rejected].includes(SheetState[sheet.state]) && <SplitRow label="Result / Mitigation">
                    <Alert><Linkify options={{target: "_blank"}}>{sheet.mitigation}</Linkify></Alert>
                  </SplitRow> }
                </Grid>
              ) : (
                <Skeleton />
              )}
            </Paper>

            <Paper sx={{ p: 4, my: 4 }}>
              <h3>
                <small>Owner Section</small>
                {sheet && SheetState[sheet.state] !== SheetState.new && <IconButton onClick={() => showOwnerSection(!showsOwnerSection)}>{showsOwnerSection ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</IconButton>}
              </h3>

              <Collapse
                in={showsOwnerSection || sheet && SheetState[sheet.state] === SheetState.new}
              >              
              { sheet && params ? <OwnerFields sheet={sheet}
                                               params={params}
                                               disabled={SheetState[sheet.state] !== SheetState.new}
                                               onChange={object => setParams({...params, ...object})}
                                               onSave={save} /> : <Skeleton /> }
              </Collapse>
            </Paper>
            
            { showsAccountableSection && (
            <Paper sx={{ p: 4, my: 4 }}>
              <h3 style={{marginBottom: "1rem"}}>
                <small>Accountable Section</small>
              </h3>
              { sheet && params ? <Grid container spacing={2}>
                <SplitRow label="Result / Mitigation">
                  <MultilineTextField
                    name="mitigation"
                    required
                    placeholder="Describe the result e.g. add a mitigation or insert a link to any useful resource that helps resolving the issue."
                    value={params?.mitigation ?? ""}
                    onChange={value => setParams({...params, mitigation: value})}
                    onSave={value => save({mitigation: value})}
                    disabled={sheet.accountable?.id !== user.id || SheetState[sheet.state] !== SheetState.draft}
                  />
                </SplitRow>
              </Grid> : <Skeleton /> }
            </Paper>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={5}>
          { sheet && <Toolbar sheet={sheet}
                              lesson={lesson}
                              user={user}
                              isUpdating={isUpdating}
                              users={users}
                              onChange={object => setParams({...params, ...object})}
                              onSave={save}
                              onAssign={assign}
                              onApprove={approve}
                              onAccept={accept}
                              onReject={reject}
                              onReopen={reopen} /> }
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditSheet;
