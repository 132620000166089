import React from 'react';
import { Stack, Divider } from '@mui/material';

const OrDivider = (props) => {
  return (
    <Stack className='or-divider' direction={props.orientation == 'vertical' ? 'column' : 'row'} spacing={2}>
      <Divider orientation={props.orientation} />
      <span className={`text-${props.color ?? "secondary"}`}>or</span>
      <Divider orientation={props.orientation} />
    </Stack>
  );
}

export default OrDivider;