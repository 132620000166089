import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Link, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { OnboardingProps } from "./WorkspaceOnboarding";
import OnboardingLayout from "./Layout";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Api from "../../network/Api";

export type UserOnboardingParams = {
  display_name?: string;
  terms_accepted?: boolean;
  beta_accepted?: boolean;
}

const UserOnboarding = (props: OnboardingProps) => {
  const [isLoading, shouldLoad] = useState<boolean>(false);
  const [params, setParams] = useState<UserOnboardingParams>({});

  useEffect(() => {
    if (!isLoading) { return }
    Api.put(`/user`, params).then(result => {
      window.location.reload();
    });
  }, [isLoading]);

  return (
    <OnboardingLayout activeStep={3}>
      <FormGroup>
        <h3>Your display name</h3>
        <FormLabel>The display name will be visible to your
          workspace team.
        </FormLabel>
      </FormGroup>
      <FormGroup>
        <TextField
          label="Your display name"
          value={params.display_name}
          onChange={e => setParams({ ...params, display_name: e.currentTarget.value })}
          variant="filled"
          required
        />
      </FormGroup>

      <FormGroup className="text-secondary" sx={{mt: 2}}>
        <FormControlLabel control={<Checkbox size="small" name="terms_accepted" checked={params.terms_accepted} onChange={(e, checked) => setParams({ ...params, terms_accepted: checked })} />} label={(
          <small>I agree to the <Link href="/terms" target="_blank">terms of service</Link> and to the <Link href="/privacy" target="_blank">privacy policy</Link>.</small>
          )} />
        <FormControlLabel control={<Checkbox size="small" name="beta_accepted" checked={params.beta_accepted} onChange={(e, checked) => setParams({ ...params, beta_accepted: checked })} />} label={(
          <small>You are participating in a beta test. By checking you accept the <Link href="/beta" target="_blank">beta test agreement</Link>.</small>
        )} />
      </FormGroup>
      {/* <FormGroup>
        <p>
          Tell us more more about your role in the company. This will help
          getting better results. You can skip this step if you want and come
          back later.
        </p>
      </FormGroup>
      <FormGroup>
        <TextField
          name="position_name"
          label="Current position"
          defaultValue={props.user.display_name}
          variant="filled"
          required
        />
      </FormGroup>
      <FormGroup>
        <TextField
          name="position_description"
          label="Main responsibility"
          defaultValue={props.user.display_name}
          variant="filled"
          required
        />
      </FormGroup> */}
      <LoadingButton
        loading={isLoading}
        disabled={!params.terms_accepted || !params.beta_accepted || !params.display_name}
        variant="outlined"
        sx={{ float: "right" }}
        startIcon={<RocketLaunchIcon />}
        onClick={() => shouldLoad(true)}
      >
        Let's go
      </LoadingButton>
    </OnboardingLayout>
  );
};

export default UserOnboarding;
