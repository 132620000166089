import React from "react";
import {
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import { TimelineAction, TimelineActionType } from "../../types/Timeline";
import { StartProps } from "../Lessons/Start";
import LessonItem, { ItemButtonActions } from "../Lessons/Item";
import { NavLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Avatar from "../Shared/Avatar";

type TimelineActionProps = {
  history: Array<TimelineAction>;
  isLoading: boolean;
  endReached: boolean;
  onLoad: () => void;
}

const TimelineActions = (props: TimelineActionProps & StartProps & ItemButtonActions) => {

  const content = (item: TimelineAction) => {
    switch (TimelineActionType[item.action_type]) {
      case TimelineActionType.workspace_created:
        return (
          <small className="text-secondary">
            <strong className="text-primary">{item.user.display_name}</strong>
            <br />
            <strong>created</strong> {props.workspace.name} workspace.
          </small>
        );
      case TimelineActionType.lesson_created:
        return (
          <small className="text-secondary">
            <strong className="text-primary">New lessons learned</strong> added:
            <LessonItem lesson={item.lesson!} onComment={props.onComment} onSheetOpen={props.onSheetOpen} disablePadding size="small" />
          </small>
        );
      case TimelineActionType.workspace_joined:
        return (
          <small className="text-secondary">
            <strong className="text-primary">{item.user.display_name}</strong>
            <br />
            <strong>joined</strong> {props.workspace.name} workspace.
          </small>
        );
      case TimelineActionType.sheet_created:
        return (
          <small className="text-secondary">
            <strong className="text-primary">{item.user.display_name}</strong><br />started working on a <NavLink to={`/sheets/${item?.sheet?.id}`}><strong className="text-secondary">SMART sheet</strong></NavLink>.
          </small>
        );
      case TimelineActionType.sheet_approved:
        return (
          <small className="text-secondary">
            <strong className="text-primary">{item.user.display_name}</strong><br />approved a <NavLink to={`/sheets/${item?.sheet?.id}`}><strong className="text-secondary">SMART sheet</strong></NavLink>.
          </small>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {props.history.length > 0 && (
        <Container maxWidth="xs">
          <List sx={{ minWidth: "300px", maxWidth: "25vw" }}>
            <ListItem>
              <h3><small>Team activity</small></h3>
            </ListItem>
            
            {props.history.map((item) => (
              <ListItem
                component={Paper}
                elevation={0}
                key={item.id}
                sx={{ mb: 1, alignItems: 'flex-start' }}
              >
                { item.user && <ListItemAvatar><Avatar size={32} user={item.user} /></ListItemAvatar> }
                <ListItemText
                  primary={content(item)}
                  secondary={
                    <small className="text-secondary">
                      {dayjs.unix(item.timestamp).format("ll")}
                    </small>
                  }
                  sx={{[`.MuiListItemText-primary`]: {lineHeight: 1.3}}}
                />
              </ListItem>
            ))}

            { !props.endReached && <ListItem sx={{justifyContent: "center"}} disablePadding>
              <LoadingButton variant="text" 
                              size="small" 
                              loading={props.isLoading}
                              onClick={props.onLoad}>Load more ...</LoadingButton>
            </ListItem> }
          </List>
        </Container>
      )}{" "}
    </>
  );
};

export default TimelineActions;
