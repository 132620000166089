import React, { useState, useEffect, useRef } from "react";
import { TableHead, Table, TableBody, TableCell, TableContainer, TableRow, ListItemText, Chip, TableFooter, TablePagination, Alert, Stack } from "@mui/material";
import Lesson from "../../../types/Lessons";
import { SheetButton, CommentButton, ItemButtonActions } from "../Item";
import { Sentiment } from "../Add/Labeling";
import Api from "../../../network/Api";
import TypingIndicator from "../../Shared/TypingIndicator";
import Linkify from "linkify-react";

const LessonsList = (props: ItemButtonActions) => {

  const [lessons, setLessons] = useState<Array<Lesson>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const scrollTopRef = useRef();

  useEffect(() => {
    Api.get(`/lessons/recent?limit=${limit}&page=${page}`).then(result => {
      setLessons(result.items);
      setTotal(result.total);
      scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }, [limit, page]);

  return (
    <TableContainer>
      <Table sx={{width: '100%', overflowX: 'auto', display: 'table'}}>
        <TableHead ref={scrollTopRef}>
          <TableRow>
            <TableCell><ListItemText><strong>Lesson learned</strong></ListItemText></TableCell>
            <TableCell align="center"><ListItemText><strong>SMART sheet</strong></ListItemText></TableCell>
            <TableCell align="center"><ListItemText><strong>Comments</strong></ListItemText></TableCell>
            <TableCell align="center"><ListItemText><strong>Sentiment</strong></ListItemText></TableCell>
            <TableCell align="right"><ListItemText><strong>Topics</strong></ListItemText></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lessons ? lessons.map((lesson) => {
            return <TableRow
              key={lesson.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Stack spacing={1}>
                  <ListItemText>{lesson.learned}</ListItemText>
                  {lesson.sheet?.mitigation && <Alert><small><Linkify options={{target: "_blank"}}>{lesson.sheet.mitigation}</Linkify></small></Alert>}
                </Stack>
              </TableCell>
              <TableCell align="center"><SheetButton lesson={lesson} onSheetOpen={props.onSheetOpen} /></TableCell>
              <TableCell align="center"><CommentButton lesson={lesson} onComment={props.onComment} /></TableCell>
              <TableCell align="center"><Sentiment {...lesson} /></TableCell>
              <TableCell align="right">{lesson.labels?.map(label => <Chip sx={{m: 0.25}} size="small" label={label} />)}</TableCell>
            </TableRow>
          }) : <TableRow>
            <TableCell colSpan={5} sx={{justifyItems: "center", padding: 5}}>
              <TypingIndicator />
            </TableCell>
          </TableRow>}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination 
              count={total} 
              page={page-1}
              rowsPerPage={limit}
              onPageChange={(e, page) => setPage(page+1)}
              onRowsPerPageChange={e => setLimit(e.target.value)}
             />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default LessonsList;
