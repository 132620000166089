import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, Button, FormGroup, TextField, MenuList, MenuItem, ListItemIcon, ListItemText, Container, Stack } from '@mui/material';
import { NavigationDrawer, ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
import Members from './Members';
import { AppProps } from '../App';
import { DemoSwitch } from '../Shared/UserNav';
import { WorkspaceOnboardingParams } from '../Onboarding/WorkspaceOnboarding';
import { LoadingButton } from '@mui/lab';
import Api from '../../network/Api';
import Workspace from '../../types/Workspace';
import ConfirmDialog from '../Shared/ConfirmDialog';

enum ContentItem {
  "workspace" = "Workspace details",
  "workspace/company" = "Company details",
  "workspace/members" = "Members"
}

export const Settings = (props: AppProps & StartProps & ToolboxProps) => {

  const { hash } = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<ContentItem>(ContentItem.workspace);

  useEffect(() => {
    const content = hash ? ContentItem[hash.replace("#", "")] : ContentItem.workspace;
    setContent(content);
  }, [hash]);

  const renderMenuIcon = (item: ContentItem) => {
    switch (item) {
      case ContentItem.workspace:
        return <DisplaySettingsIcon />
      case ContentItem['workspace/company']:
        return <BusinessCenterIcon />
      case ContentItem['workspace/members']:
        return <GroupIcon />
    }
  }
  
  const renderCurrentContent = (item: ContentItem) => {
    switch (item) {
      case ContentItem.workspace:
        return <Details {...props} />
      case ContentItem['workspace/company']:
        return <Company {...props} />
      case ContentItem['workspace/members']:
        return <Members {...props} />
    }
  }
  
  return <NavigationDrawer menu={<MenuList disablePadding>
    { Object.values(ContentItem).map((item, index) => <MenuItem key={item} selected={content == item} onClick={() => navigate(`#${Object.keys(ContentItem)[index]}`)}>
      <ListItemIcon>{renderMenuIcon(item)}</ListItemIcon>
      <ListItemText>{item}</ListItemText></MenuItem>)}
    </MenuList>} currentContent={<Container maxWidth="md">{renderCurrentContent(content)}</Container>} 
  />;
};

const Details = (props: AppProps & ToolboxProps) => {

  const [isSaving, shouldSave] = useState<boolean>(false);
  const [params, setParams] = useState<WorkspaceOnboardingParams>({});
  const [workspace, setWorkspace] = useState<Workspace>();

  useEffect(() => {
    Api.get("/workspace").then(result => {
      setWorkspace(result);
      setParams({ name: result.name });
    });
  }, []);

  useEffect(() => {
    if (!isSaving) { return }
    Api.put(`/workspace`, params).then((result: Workspace) => {
      props.onClose();
      window.location.reload();
    });
  }, [isSaving]);

  const [willDestroy, wantDestroy] = useState<boolean>(false);
  const [destroy, shouldDestroy] = useState<boolean>(false);

  useEffect(() => {
    if (!destroy) { return }
    Api.delete(`/workspace`).then(result => {
      window.location.reload();
    });
  }, [destroy]);
  
  return <>{ workspace && <>
    <FormGroup>
      <TextField name="name" required label="Workspace Name" value={params.name} variant="filled" onChange={e => setParams({ ...params, name: e.target.value })} />
    </FormGroup>

    <FormGroup>
      <TextField disabled name="website" label="Website" type="url" value={workspace.website} required variant="filled" />
    </FormGroup>

    <FormGroup>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button variant="text" color="secondary" onClick={props.onClose}>Cancel</Button>
        <LoadingButton variant="outlined" color="primary" loading={isSaving} onClick={() => shouldSave(true)}>Save</LoadingButton>
      </Stack>
    </FormGroup>

    <FormGroup>
      <Divider />
    </FormGroup>

    <FormGroup sx={{alignItems: "center"}}>
      <span className="text-secondary">
        <DemoSwitch checked={props.workspace?.demo !== true}
            onChange={(e, off) => props.onSwitchDemo!(!off)} />
      </span>
    </FormGroup>

    <FormGroup>
      <Button variant="text" color="error" onClick={() => wantDestroy(true)}>Delete workspace</Button>
    </FormGroup>

    { willDestroy && <ConfirmDialog title="Delete workspace" 
                                    message="Do you really want to delete this workspace with all its users and data?"
                                    onCancel={() => wantDestroy(false)}
                                    onConfirm={() => shouldDestroy(true)} /> }
  </>} </>;
};

const Company = (props: StartProps & ToolboxProps) => {

  const [isSaving, shouldSave] = useState<boolean>(false);
  const [params, setParams] = useState<WorkspaceOnboardingParams>({});
  const [workspace, setWorkspace] = useState<Workspace>();

  useEffect(() => {
    Api.get("/workspace").then((result: Workspace) => {
      setWorkspace(result);
      setParams({ industry: result.industry, description: result.description, vision: result.vision, mission: result.mission });
    });
  }, []);

  useEffect(() => {
    if (!isSaving) { return }
    Api.put(`/workspace`, params).then(result => {
      props.onClose();
      window.location.reload();
    });
  }, [isSaving]);

  return <>{ workspace && <>
    <FormGroup>
      <TextField name="industry" label="Industry" value={params.industry} variant="filled" onChange={e => setParams({ ...params, industry: e.target.value })} />
    </FormGroup>

    <FormGroup>
      <TextField name="description" label="Main Business Purpose" value={params.description} variant="filled" onChange={e => setParams({ ...params, description: e.target.value })} />
    </FormGroup>

    <FormGroup>
      <TextField multiline rows={2} name="vision" label="Comany Vision" value={params.vision} variant="filled" onChange={e => setParams({ ...params, vision: e.target.value })} />
    </FormGroup>

    <FormGroup>
      <TextField multiline rows={2} name="mission" label="Company Mission" value={params.mission} variant="filled" onChange={e => setParams({ ...params, mission: e.target.value })} />
    </FormGroup>

    <Stack direction="row" spacing={1} justifyContent="center">
      <Button variant="text" color="secondary" onClick={props.onClose}>Cancel</Button>
      <LoadingButton variant="outlined" color="primary" loading={isSaving} onClick={() => shouldSave(true)}>Save</LoadingButton>
    </Stack>
  </> } </>;
};

export default Settings;