import React from "react";
import { Container, Card } from "@mui/material";
import LoginForm from "./LoginForm";
import { AppProps } from "../App";
import OnboardingLayout from "../Onboarding/Layout";

type LoginProps = {
  layout: "onboarding" | "default";
}

const Login = (props: AppProps & LoginProps) => {
  return (
    <>
      {props.workspace || props.layout == "onboarding" ? (
        <OnboardingLayout title={props.workspace && `${props.workspace.name} is waiting for you`}>
          <LoginForm />
        </OnboardingLayout>
      ) : (
        <Container component={Card} maxWidth="xs">
          <LoginForm />
        </Container>
      )}
    </>
  );
};

export default Login;
