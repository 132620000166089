import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Container,
  FormGroup,
  Stack,
  TextField,
  FormLabel,
} from "@mui/material";
import Api from "../../network/Api";
import { WorkspaceSelectionProps } from "./Selection";
import OrDivider from "../Shared/OrDivider";
import JoinableList from "./JoinableList";
import Workspace from "../../types/Workspace";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { WorkspaceOnboardingParams } from "../Onboarding/WorkspaceOnboarding";
import { LoadingButton } from "@mui/lab";

const NewWorkspace = (props: WorkspaceSelectionProps) => {
  const [joinable, setJoinable] = useState<Array<Workspace> | undefined>();
  const [isLoading, shouldLoad] = useState<boolean>(false);
  const [params, setParams] = useState<WorkspaceOnboardingParams>({});
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    Api.get(
      `/workspaces/new${queryParams.get("join_token") ? `?join_token=${queryParams.get("join_token")}` : ""}`,
    ).then((result: WorkspaceSelectionProps) => {
      setJoinable(result.joinable);
    });
  }, []);

  useEffect(() => {
      if (!isLoading) { return }
      Api.post(`/workspaces`, params).then(result => window.location.replace(result.url));
    }, [isLoading]);

  return (
    <Container component={Card} maxWidth="sm">
      <FormGroup sx={{mb: 2}}>
        <h2>Create a new Lessonice workspace</h2>
        <FormLabel>
          Lessonice gives your team a place to exchange lessons learned and
          generate valuable mitigation actions to prevent those mistakes in the
          future.
        </FormLabel>
      </FormGroup>

      <FormGroup>
        <TextField
          name="name"
          value={params.name}
          onChange={e => setParams({name: e.currentTarget.value})}
          label="Workspace name"
          variant="filled"
          required
        />
      </FormGroup>

      <FormGroup>
        <LoadingButton variant="outlined" color="primary" loading={isLoading} disabled={!params.name} startIcon={<RocketLaunchIcon />} onClick={() => shouldLoad(true)}>
          Create workspace
        </LoadingButton>
      </FormGroup>

      {joinable && joinable.length > 0 && (
        <section
          className="bg-dark"
          style={{ margin: "2rem -2rem -2rem", padding: "2rem" }}
        >
          <div
            className="bg-dark"
            style={{
              width: 80,
              height: 80,
              margin: "-4rem auto -1rem",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 40,
            }}
          >
            <OrDivider color="white" />
          </div>

          <Stack gap={2}>
            <h3 className="text-white">Accept invitation</h3>
            <JoinableList user={props.user} joinable={joinable} />
          </Stack>
        </section>
      )}
    </Container>
  );
};

export default NewWorkspace;
