import React from 'react';
import { useLocation } from 'react-router-dom';
import { WorkspaceSelectionProps } from './Selection';
import { Paper, List, ListItem, ListItemText, Stack, Button } from '@mui/material';
import WorkspaceItem from './ListItem';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Api from '../../network/Api';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const JoinableList = (props: WorkspaceSelectionProps) => {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const join = (workspaceId: string) => {
    Api.post(`/workspaces/${workspaceId}/join`, {join_token: queryParams.get('join_token')}).then(result => window.location.replace(result.url));
  }

  return <Paper className="invite-list"><List>
    {
      props.joinable && props.joinable.map(workspace => <ListItem>
        <ListItemText>
          { workspace.whitelisted && <small className="text-secondary"><MailOutlineIcon fontSize="small" sx={{verticalAlign: "-0.3rem"}} /> <strong>Anyone with @{ workspace.domain } can join</strong></small> }
          <Stack direction="row" sx={{mt: '0.5rem'}}>
            <WorkspaceItem workspace={workspace} />
            <Button variant="outlined" size="small" color="primary" onClick={() => join(workspace.id)} sx={{ml: 'auto'}} endIcon={<ArrowForwardIcon />}>Join workspace</Button>
          </Stack>
        </ListItemText>
      </ListItem>)
    }
  </List></Paper>
}

export default JoinableList;