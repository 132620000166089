import React from 'react';
import ApexChart from "react-apexcharts";
import dayjs from 'dayjs';

const FilledChart = (props) => {

  let options = {
    chart: {
      toolbar: {
        show: false
      },
      background: 'transparent',
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: "dark"
    },
    colors: ['#03c9d7'],
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      categories: props.series.map(series => dayjs(series.date).format("ll")),
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    }
  };

  let series = [
    {
      name: "Created",
      data: props.series.map(series => series.amount)
    }
  ];

  return (
    <ApexChart
      height={"90"}
      options={options}
      series={series}
      type="area"
    />
  );
};

export default FilledChart;