import React, { useState, useEffect } from "react";
import { Button, Stack, FormGroup, Tooltip, ListItemText, Grid, Divider, Paper } from "@mui/material";
import Lesson from "../../types/Lessons";
import User from "../../types/User";
import UserAutocomplete from "../Shared/UserAutocomplete";
import ShareIcon from '@mui/icons-material/Share';
import Actions, { ActionProps } from "./Actions";
import CommentBox from "../Comments/CommentBox";
import SplitRow from "./SplitRow";
import { ChangeProps } from "./Sheet";
import { SheetState } from "../../types/Sheet";
import { SheetButton } from "../Lessons/Item";

type ToolbarProps = {
  lesson?: Lesson;
  users: Array<User>;
}

const Toolbar = (props: ToolbarProps & ActionProps & ChangeProps) => {

  const [copyLink, shouldCopyLink] = useState<boolean>(false);
  const usersSelectable = SheetState[props.sheet.state] === SheetState.new || (props.sheet.owner?.id === props.user.id && SheetState[props.sheet.state] !== SheetState.approved);

  useEffect(() => {
    if (typeof(props.sheet) === "undefined" || !copyLink) { return }
    navigator.clipboard.writeText(props.sheet.url);
  }, [copyLink]);

  const shareDialog = () => {
    if (typeof(props.sheet) === "undefined") { return }
    if (typeof(navigator.share) !== "undefined") {
      navigator.share({url: props.sheet.url}).then(fulfilled => {
        if (typeof(fulfilled) === "undefined") {
          shouldCopyLink(true);
        }
      }).catch(rejected => shouldCopyLink(true));
      return;
    }
  }

  return (
    <Stack spacing={3} sx={{mt: 2, mb: 4}}>
      <Paper sx={{py: 2, px: 3}}>
        <FormGroup>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <h3><small>Revision</small></h3>
            <Tooltip 
              title={`Link copied`} 
              open={copyLink}>
                <Button
                  size="small"
                  variant='text'
                  startIcon={<ShareIcon />}
                  onClick={shareDialog}>Share sheet</Button>
              </Tooltip>
          </Stack>
        </FormGroup>
        
        <Grid container spacing={2}>
        { props.sheet && props.lesson && <>
          <SplitRow label="State">
            <ListItemText sx={{margin: 0, mt: -0.75}}><small>{ String(props.sheet.state).toUpperCase() }</small> <SheetButton lesson={props.lesson} size="small" /></ListItemText>
          </SplitRow>

          { props.sheet?.owner && <SplitRow label="Owner">
            <ListItemText sx={{margin: 0}}><small>{ props.sheet.owner.display_name }</small></ListItemText>
          </SplitRow> }

          { !usersSelectable ? <SplitRow label="Accountable">
            <ListItemText sx={{margin: 0}}><small>{props.sheet.accountable?.display_name}</small></ListItemText>
          </SplitRow> : <SplitRow label="Accountable">
            <UserAutocomplete
              options={props.users ?? []}
              value={props.sheet.accountable}
              placeholder="Add an accountable"
              onChange={user => {
                let newParam = { accountable: user?.id ?? null }
                props.onChange(newParam);
                props.onSave(newParam);
              }}
            />
          </SplitRow> }

          { !usersSelectable ? <SplitRow label="Responsible approver">
            <ListItemText sx={{margin: 0}}><small>{props.sheet.approver?.display_name}</small></ListItemText>
          </SplitRow> : <SplitRow label="Responsible approver">
            <UserAutocomplete
              options={props.users ?? []}
              value={props.sheet.approver}
              placeholder="Add a responsible approver"
              onChange={user => {
                let newParam = { approver: user?.id ?? null }
                props.onChange(newParam);
                props.onSave(newParam);
              }}
            />
          </SplitRow> }

          <Grid item xs={12}>
            <Divider sx={{mb: 2}} />
            <Actions {...props} />
          </Grid>
          </> }
        </Grid>
      </Paper>

      { props.lesson && <CommentBox user={props.user} lesson={props.lesson} /> }

    </Stack>
  )
}

export default Toolbar;