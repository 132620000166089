import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landingpage from "./Pages/Landingpage";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locale from "../locales/de.json";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/de";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

import Layout from "./Layouts/Application";

import LessonsLearned from "./Lessons/Start";
import VerificationForm from "./Authentication/VerificationForm";
import UserOnboarding from "./Onboarding/UserOnboarding";
import WorkspaceOnboarding from "./Onboarding/WorkspaceOnboarding";
// import WorkspaceSelection from "./Workspaces/Selection";
import NewWorkspace from "./Workspaces/New";
import User from "../types/User";
import { FlashProps } from "../types/View";
import Workspace from "../types/Workspace";
import EditSheet from "./Sheets/Sheet";
import WorkspaceSelection from "./Workspaces/Selection";
import Login from "./Authentication/Login";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import BetaTest from "./Pages/Beta";
import LegalNotice from "./Pages/Legal";

i18n.use(initReactI18next).init({
  resources: locale,
  lng: "de",
});

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.locale("de");
dayjs.extend(localizedFormat);

export type AppProps = {
  user?: User;
  workspace?: Workspace;
  flash?: Array<FlashProps>;
  onSwitchDemo?: (on: boolean) => void;
};

const App = (props: AppProps) => {
  const MainApp = (
    <Layout variant="application" {...props}>
      <LessonsLearned
        user={props.user!}
        workspace={props.workspace!}
      ></LessonsLearned>
    </Layout>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/terms" element={<Layout variant="info" {...props}><Terms /></Layout>} />
        <Route path="/privacy" element={<Layout variant="info" {...props}><Privacy /></Layout>} />
        <Route path="/beta" element={<Layout variant="info" {...props}><BetaTest /></Layout>} />
        <Route path="/legal" element={<Layout variant="info" {...props}><LegalNotice /></Layout>} />

        <Route
          path="/signup"
          element={
            <Layout variant="info" {...props}>
              <Login {...props} layout="onboarding" />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={
            <Layout variant="info" {...props}>
              <Login {...props} layout="default" />
            </Layout>
          }
        />
        <Route
          path="/verifications/:verificationId"
          element={
            <Layout variant="info" {...props}>
              <VerificationForm />
            </Layout>
          }
        />
        {/* <Route path="/login/forgot" exact element={this.renderLayout({ info: true, view: <ForgotPwdForm /> })} /> */}
        {/* <Route path="/eusers/:userId/authentication/:authId/edit" exact element={this.renderLayout({ info: true, view: <ResetPwdForm /> })} /> */}

        {props.user && (
          <>
            <Route
              path="/workspaces"
              element={
                <Layout variant="info" {...props}>
                  <WorkspaceSelection user={props.user} />
                </Layout>
              }
            />
            <Route
              path="/workspaces/new"
              element={
                <Layout variant="info" {...props}>
                  <NewWorkspace user={props.user} />
                </Layout>
              }
            />
            <Route
              path="/onboarding/user"
              element={
                <Layout variant="info" {...props}>
                  <UserOnboarding user={props.user} />
                </Layout>
              }
            />
            <Route
              path="/onboarding/workspace"
              element={
                <Layout variant="info" {...props}>
                  <WorkspaceOnboarding user={props.user} />
                </Layout>
              }
            />

            {props.workspace && (
              <>
                <Route path="/app" element={MainApp} />
                <Route path="/lessons" element={MainApp} />
                <Route path="/chat" element={MainApp} />
                <Route path="/dashboard" element={MainApp} />

                <Route
                  path="/sheets/:sheetId"
                  element={
                    <Layout variant="application" {...props}>
                      <EditSheet {...props.user} />
                    </Layout>
                  }
                />
              </>
            )}
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;
