import React, { useState, useEffect } from "react";
import { FilledInput, FormGroup, FormLabel, IconButton, List, ListItem, Paper } from "@mui/material";
import User from "../../types/User";
import SendIcon from "@mui/icons-material/Send";
import Lesson from "../../types/Lessons";
import Api from "../../network/Api";
import CommentItem from "./Item";
import Comment from "../../types/Comment";
import { LoadingButton } from "@mui/lab";

type CommentBoxProps = {
  user: User;
  lesson: Lesson;
}

const CommentBox = (props: CommentBoxProps) => {

  const [comments, setComments] = useState<Array<Comment>>([]);
  const [comment, setComment] = useState<string>("");
  const [isSending, shouldSend] = useState<boolean>(false);
  const [endReached, setEndReached] = useState<boolean>(true);
  const [isLoading, shouldLoad] = useState<boolean>(false);

  useEffect(() => {
    shouldLoad(true)  
  }, []);

  useEffect(() => {
    if (!isLoading) { return }
    Api.get(`/lessons/${props.lesson.id}/comments?limit=5${comments.length > 0 ? `&after=${comments[comments.length - 1].created_at}` : ''}`).then(result => {
      shouldLoad(false);
      setComments(current => {
        let newList = [...current, ...result.items];
        setEndReached(newList.length >= result.total);
        return newList;
      });
    });
  }, [isLoading]);

  useEffect(() => {
    if (comment === "" || !isSending) { return }
    Api.post(`/lessons/${props.lesson.id}/comments`, { content: comment }).then(comment => {
      setComments((current) => [comment, ...current]);
      setComment("");
      shouldSend(false);
    });
  }, [isSending]);

  const highlight = (id: string, color: string | null) => {
    Api.put(`/lessons/${props.lesson.id}/comments/${id}`, {color: color}).then(comment => {
      setComments((current) => {
        return current.map((item) => {
          if (item.id === id) {
            return comment;
          } else {
            return item;
          }
        });
      });
    })
  }

  const deleteComment = (id: string) => {
    Api.delete(`/lessons/${props.lesson.id}/comments/${id}`).then(result => {
      setComments((current) => {
        return current.filter((item) => item.id !== id);
      });
    })
  }

  return (
    <Paper sx={{py: 2, px: 3}}>
      <FormGroup>
        <h3><small>Comments</small></h3>
      </FormGroup>

      <FormGroup>
        <FilledInput
          placeholder="Write a comment"
          fullWidth
          autoComplete="off"
          name="lesson"
          size="small"
          endAdornment={
            <IconButton
              size="small"
              sx={{ ml: "0.5rem" }}
              disabled={isSending}
              onClick={() => shouldSend(true)}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          }
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Enter" || isSending) {
              return;
            }
            shouldSend(true);
          }}
        />
      </FormGroup>

      { comments?.length <= 0 ? <FormGroup><FormLabel>No comments have been posted. Be the first one.</FormLabel></FormGroup> : <>
        <List>
          {comments.map((comment) => (
            <CommentItem key={comment.id}
                         user={props.user} 
                         comment={comment}
                         onHighlight={color => highlight(comment.id, color)}
                         onDelete={() => deleteComment(comment.id)} />
          ))}
          { !endReached && <ListItem sx={{justifyContent: "center"}}>
              <LoadingButton variant="text" 
                              color="primary"
                              size="small" 
                              loading={isLoading}
                              onClick={() => shouldLoad(true)}>Load more ...</LoadingButton>
            </ListItem> }
        </List>
      </> }
    </Paper>
  );
}

export default CommentBox;