import React, { useState } from "react";
import { Button, FormLabel, Stack, TextField } from "@mui/material";
import User from "../../types/User";
import { SheetState } from "../../types/Sheet";

import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { LoadingButton } from "@mui/lab";
import Toolbox from "../Shared/Toolbox";
import { ChangeProps } from "./Sheet";

export type ActionProps = {
  user: User;
  isUpdating: boolean;
  onAssign: () => void;
  onApprove: () => void;
  onAccept: () => void;
  onReject: (comment?: string) => void;
  onReopen: () => void;
}

const Actions = (props: ActionProps & ChangeProps) => {

  const OwnerActions = () => {
    return <>
      <LoadingButton loading={props.isUpdating} variant="outlined" color="primary" size="small" disabled={typeof(props.sheet?.accountable) === "undefined"} onClick={props.onAssign} startIcon={<AssignmentIndIcon />}>Assign to accountable</LoadingButton>
    </>
  }

  const AccountableActions = () => {
    return <>
      <LoadingButton loading={props.isUpdating} variant="outlined" color="primary" size="small" disabled={typeof(props.sheet?.approver) === "undefined" || !props.sheet?.mitigation} onClick={props.onApprove} startIcon={<BookmarkAddedIcon />}>Send to approval</LoadingButton>
    </>
  }

  const ApproverActions = () => {
    const [reject, shouldReject] = useState<boolean>(false);
    const [rejectComment, setRejectComment] = useState<string>();

    return <>
      <LoadingButton loading={props.isUpdating} variant="outlined" color="error" size="small" onClick={() => shouldReject(true)} startIcon={<DoDisturbIcon />}>Reject</LoadingButton>
      <LoadingButton loading={props.isUpdating} variant="outlined" color="primary" size="small" onClick={props.onAccept} startIcon={<CheckIcon />}>Approve</LoadingButton>

      { reject && <Toolbox title="Reject review" maxWidth="sm" onClose={() => shouldReject(false)}>
        <FormLabel>Do you want to add a reason?</FormLabel>
        <TextField variant="filled" multiline rows={3} placeholder="Reason" value={rejectComment} onChange={e => setRejectComment(e.currentTarget.value)} />
        <Button variant="outlined" color="secondary" onClick={() => props.onReject(rejectComment)}>Confirm</Button>
      </Toolbox> }
    </>
  }

  const DoneActions = () => {
    return <>
      <LoadingButton loading={props.isUpdating} variant="text" color="primary" size="small" onClick={props.onReopen}>Reopen for edit</LoadingButton>
    </>
  }

  const renderContent = () => {
    switch (SheetState[props.sheet.state]) {
      case SheetState.approved:
        if (props.sheet?.approver?.id === props.user.id) {
          return <DoneActions />
        } break;
      case SheetState.rejected:
        return <DoneActions />
      case SheetState.draft:
        if (props.sheet?.accountable?.id === props.user.id) {
          return <AccountableActions />
        } break;   
      case SheetState.review:
        if (props.sheet?.approver?.id === props.user.id) {
          return <ApproverActions />
        } break;
      case SheetState.new:
        return <OwnerActions />
      default:
        return <></>;
    }
  }

  return <Stack direction="row" spacing={1} justifyContent="center">{renderContent()}</Stack>
}

export default Actions;