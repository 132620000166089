import React from "react";
import { Card, FormGroup } from "@mui/material";

const BetaTest = () => {
  return (
    <Card>
      <FormGroup>
      <h2>Beta test agreement</h2>
      </FormGroup>
      <FormGroup>
      <h3>1. Purpose</h3>
    <p>By participating in the beta test of Lessonice, you acknowledge that the service is in a developmental phase and may contain bugs, inaccuracies, or other issues.</p>
    </FormGroup>
    <FormGroup>
      <h3>2. No Warranty</h3>
    <p>Lessonice is provided “as is” during the beta phase:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">There is no guarantee of service availability or reliability.</li>
        <li className="text-primary">The AI model is undergoing active training and may produce random or incorrect responses.</li>
        <li className="text-primary">Critical decisions should not be based on the tool's outputs.</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>3. User Responsibilities</h3>
    <p>As a beta tester, you agree to:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">Use the platform for testing purposes only.</li>
        <li className="text-primary">Report any issues or feedback to help improve the system.</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>4. Limitation of Liability</h3>
    <p>Innomaze FlexCo is not liable for:</p>
    <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
        <li className="text-primary">Errors, inaccuracies, or omissions in the service.</li>
        <li className="text-primary">Any decisions made based on the platform's output.</li>
        <li className="text-primary">Damages or losses arising from service downtime or errors.</li>
    </ul>
    </FormGroup>
    <FormGroup>
      <h3>5. Termination</h3>
    <p>We may terminate the beta test program or your access to it at any time without notice.</p>
    </FormGroup>
    <FormGroup>
      <h3>6. Feedback</h3>
    <p>Feedback provided during the beta phase becomes the property of Innomaze FlexCo and may be used to improve the product.</p>
    </FormGroup>
    <FormGroup>
      <h3>7. Governing Law</h3>
    <p>This agreement is governed by the laws of Austria.</p>
    </FormGroup>
    </Card>
  );
};

export default BetaTest;
