import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Button, Switch, FormControlLabel, Alert, SwitchProps } from '@mui/material';
import UserMenu from './UserMenu';
import Avatar from './Avatar';
import Logo from './Logo';
import { LayoutProps } from '../../types/View';
import { AppProps } from '../App';
import MailIcon from '@mui/icons-material/Mail';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { Role } from '../../types/User';
import InsightsIcon from '@mui/icons-material/Insights';

export const DemoSwitch = (props: SwitchProps) => {
  return <FormControlLabel 
      control={<Switch {...props} />} 
      label={<small>{props.checked ? "Production on" : "Switch to production"}</small>}
      sx={{ml: 1}} />
}

const UserNav = (props: LayoutProps & AppProps) => {

  const [highlightsInviteButton, shouldHighlightInviteButton] = useState(false);
  const [showsUserMenu, setShowsUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [leaveDemo, shouldLeaveDemo] = useState<boolean>(false);

  useEffect(() => {
    window.setTimeout(() => {
      shouldHighlightInviteButton(true);
    }, 10000)
  }, []);

  const toggleMenu = (event) => {
    setShowsUserMenu(!showsUserMenu);
    setAnchorEl(event.currentTarget);
  }

  return (
    <AppBar component="nav" position='relative' className="bg-secondary">
      <Toolbar sx={{gap: 1}}>
        <Logo color="primary" to="/app" icon />

        <span style={{marginLeft: 'auto'}}></span>

        {/* <LevelProgress user={props.user!} /> */}

        <Button 
          component={NavLink} 
          className={highlightsInviteButton ? "animate__animated animate__tada" : ""}
          to="#invite"
          variant="outlined" 
          color="primary" 
          size="small" 
          startIcon={<MailIcon />}
          sx={{display: {xs: 'none', sm: 'flex'}}}>Invite team</Button>

        <Button
          color="secondary"
          size="small"
          startIcon={<InsightsIcon />}
          component={NavLink}
          to="/dashboard">Insights</Button>

        <IconButton size="small"
                    onClick={toggleMenu}>
          <Avatar size={32} user={props.user} />
        </IconButton>
        <UserMenu {...props}
                  anchorEl={anchorEl} 
                  open={showsUserMenu}
                  onClose={toggleMenu} />
      </Toolbar>

      { (props.workspace?.demo || props.workspace?.knowledge_gained !== true) && <>
        <Alert variant="filled" severity={props.workspace?.demo ? "error" : "success"} icon={false}>
          { props.workspace?.demo ? "You are working on a randomly generated demo set that allows you to interact with the tool prior to adding your own. Data you add may be removed." : "You are working in production mode. This info disappears once you've gained some knowledge." }
          
          { Role[props.user.role] === Role.admin && <DemoSwitch size="small" color="secondary" checked={props.workspace?.demo !== true} onChange={(e, off) => {
            if (props.workspace?.demo) {
              shouldLeaveDemo(true);
            } else {
              props.onSwitchDemo!(!off);
            }
          }} /> }
        </Alert>
        
        { leaveDemo && <ConfirmDialog title="Leave demo" 
                    message="Cou can switch back to demo mode anytime again from your workspace settings."
                    onCancel={() => shouldLeaveDemo(false)}
                    onConfirm={() => props.onSwitchDemo!(false)} /> }
      </> }
    </AppBar>
  );
}

export default UserNav;