import React, { useState, useEffect } from "react";
import {
  FormGroup,
  TextField,
  FormControlLabel,
  Switch,
  FormLabel,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Api from "../../network/Api";
import User from "../../types/User";
import Workspace from "../../types/Workspace";
import OnboardingLayout from "./Layout";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export type OnboardingProps = {
  user: User;
};

export type WorkspaceOnboardingParams = {
  name?: string;
  domain_whitelist?: "true" | "false"
  website?: string;
  domain?: string;
  industry?: string;
  description?: string;
  vision?: string;
  mission?: string;
  is_ready?: "true" | "false";
};

const WorkspaceOnboarding = (props: OnboardingProps) => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [suggestedContent, setSuggestedContent] = useState<WorkspaceOnboardingParams>();
  const [params, setParams] = useState<WorkspaceOnboardingParams>({});
  const [isLoading, shouldLoad] = useState<boolean>(false);

  useEffect(() => {
    Api.get(`/workspace`).then(result => {
      setWorkspace(result);
      setParams({...params, ...result});
    });
  }, []);

  useEffect(() => {
    if (!isLoading) { return }
    Api.put(`/workspace`, params).then(result => {
      if (workspace?.website || suggestedContent) {
        window.location.reload();
        return;
      }
      setSuggestedContent(result);
      shouldLoad(false);
    });
  }, [isLoading]);

  const hostFrom = (urlString?: string) => {
    if (!urlString) { return null }
    if (!params.domain_whitelist) {
      setParams({...params, domain_whitelist: "true"});
    }
    try {
      if (!urlString.startsWith('http://') && !urlString.startsWith('https://')) {
          urlString = `http://${urlString}`;
      }
      const url = new URL(urlString);
      return url.host.replace("www.", "");
    } catch (error) {
      return null;
    }
  }

  return (
    <OnboardingLayout activeStep={workspace?.website ? 2 : 1}><>
      {workspace && <>
        { workspace.website || suggestedContent ? <>
          <Details params={workspace.website ? params : suggestedContent} setParams={setParams} />

          <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
            <LoadingButton loading={isLoading} variant="text" color="primary" onClick={() => {
              setParams({ ...params, is_ready: "true" });
              shouldLoad(true);
            }}>I'll do this later</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={() => {
              setParams({ ...params, is_ready: "true" });
              shouldLoad(true);
            }} endIcon={<ArrowRightAltIcon />}>Next</LoadingButton>
          </Stack>
        </> : <>
          <FormGroup>
            <TextField
              name="name"
              label="Name of workspace"
              defaultValue={workspace.name}
              variant="filled"
              required
              onChange={(e) => {
                setParams({ ...params, name: e.target.value });
              }}
            />
          </FormGroup>
          
          <FormGroup>
            <TextField
              name="website"
              label="Company or project website"
              defaultValue={workspace.website}
              variant="filled"
              required
              onChange={(e) => {
                setParams({ ...params, website: e.target.value });
              }}
            />
          </FormGroup>

          {hostFrom(params.website) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      setParams({
                        ...params,
                        domain_whitelist: e.target.value.toString(),
                      });
                    }}
                    defaultChecked={params.domain_whitelist != "false"}
                  />
                }
                label={<span className="text-secondary">Let anyone with a <strong>@{hostFrom(params.website)}</strong> email join this workspace automatically.</span>}
              />
            </FormGroup>
          )}
          <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
            <LoadingButton loading={isLoading} disabled={!hostFrom(params.website)} variant="outlined" onClick={() => shouldLoad(true)} endIcon={<ArrowRightAltIcon />}>Next</LoadingButton>
          </Stack>
        </> }
      </>}
    </></OnboardingLayout>
  );
};

type DetailProps = {
  params: WorkspaceOnboardingParams;
  setParams: (params: WorkspaceOnboardingParams) => void;
}

const Details = (props: DetailProps) => {
  return (
    <>
      <FormGroup sx={{mb: 2}}>
        <h3>Context - Why is it relevant?</h3>
        <FormLabel>
          It gives our Artificial Intelligence more context to better understand and generate results.
        </FormLabel>
      </FormGroup>

      <FormGroup>
        <TextField
          name="industry"
          label="Industry"
          defaultValue={props.params.industry}
          variant="filled"
          onChange={(e) => {
            props.setParams({ ...props.params, industry: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          name="description"
          label="Main purpose of business"
          defaultValue={props.params.description}
          variant="filled"
          onChange={(e) => {
            props.setParams({ ...props.params, description: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          name="vision"
          label="Company vision"
          defaultValue={props.params.vision}
          variant="filled"
          multiline
          rows={2}
          onChange={(e) => {
            props.setParams({ ...props.params, vision: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          name="mission"
          label="Company mission"
          defaultValue={props.params.mission}
          variant="filled"
          multiline
          rows={2}
          onChange={(e) => {
            props.setParams({ ...props.params, mission: e.target.value });
          }}
        />
      </FormGroup>
    </>
  )
}

export default WorkspaceOnboarding;
