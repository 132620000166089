import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import User from "../../types/User";

type UserAutocompleteProps = {
  options: Array<User>;
  value?: User;
  disabled?: boolean;
  onChange: (user: User | null) => void;
  placeholder: string;
};

const UserAutocomplete = (props: UserAutocompleteProps) => (
  <Autocomplete
    options={props.options}
    defaultValue={props.value}
    fullWidth
    disabled={props.disabled}
    getOptionLabel={(user) => user.display_name}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    onChange={(e, user) => props.onChange(user)}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="filled"
        size="small"
        placeholder={props.placeholder}
      />
    )}
  />
);

export default UserAutocomplete;