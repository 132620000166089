import React from "react";
import { ListItemText, ListItem, Link, ListItemAvatar, ListItemIcon, MenuItem, Button, Alert } from "@mui/material";
import Comment from "../../types/Comment";
import dayjs from "dayjs";
import Linkify from 'linkify-react';
import Avatar from "../Shared/Avatar";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreMenu from "../Shared/MoreMenu";
import User from "../../types/User";

type CommentItemProps = {
  comment: Comment;
  user: User;
  onHighlight: (color: string | null) => void;
  onDelete: () => void;
}

const CommentItem = (props: CommentItemProps) => {

  const commentContent = () => {
    return <span style={{wordWrap: "break-word"}}>
      <strong>{props.comment.user.display_name}</strong><br /><Linkify options={{target: "_blank"}}>{props.comment.content}</Linkify>
    </span>
  }

  return (
    <ListItem disablePadding>
      <ListItemAvatar><Avatar size={32} user={props.comment.user} /></ListItemAvatar>
      <ListItemText
        primary={props.comment.color ? <Alert severity={props.comment.color} icon={false} sx={{py: 0}}>{commentContent()}</Alert> : <small>{commentContent()}</small>}
        secondary={
          <small className="text-secondary">
            {dayjs.unix(props.comment.created_at).format("lll")}
          </small>
        }
      />
      <ListItemIcon sx={{paddingTop: "4px"}}>
        <MoreMenu icon={<MoreHorizIcon />}>
          {/* { !props.comment.color && <MenuItem onClick={() => props.onHighlight("warning")}>Highlight</MenuItem> }
          { props.comment.color && <MenuItem onClick={() => props.onHighlight(null)}>Unhighlight</MenuItem> } */}
          { props.user.id === props.comment.user.id && <MenuItem onClick={props.onDelete} sx={{color: "var(--color-red) !important"}}>Delete</MenuItem> }
        </MoreMenu>
      </ListItemIcon>
    </ListItem>
  );
}

export default CommentItem;