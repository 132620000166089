import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Divider, FormGroup, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, TextField } from '@mui/material';
import { PasswordForm } from '../Shared/FormItems';
import { NavigationDrawer, ToolboxProps } from "../Shared/Toolbox";
import { StartProps } from '../Lessons/Start';

import PersonIcon from '@mui/icons-material/Person';
import GppGoodIcon from '@mui/icons-material/GppGood';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { LoadingButton } from '@mui/lab';
import { UserOnboardingParams } from '../Onboarding/UserOnboarding';
import Api from '../../network/Api';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { Role } from '../../types/User';

enum ContentItem {
  "user" = "Profile details",
  // security = "Security & Privacy",
  "user/workspace" = "Workspace membership"
}

export const Settings = (props: StartProps & ToolboxProps) => {

  const { hash } = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<ContentItem>(ContentItem.user);

  useEffect(() => {
    const content = hash ? ContentItem[hash.replace("#", "")] : ContentItem.user;
    setContent(content);
  }, [hash]);

  const renderMenuIcon = (item: ContentItem) => {
    switch (item) {
      case ContentItem.user:
        return <PersonIcon />
      // case ContentItem.security:
      //   return <GppGoodIcon />
      case ContentItem['user/workspace']:
        return <Diversity3Icon />
    }
  }
  
  const renderCurrentContent = (item: ContentItem) => {
    switch (item) {
      case ContentItem.user:
        return <ProfileSettings {...props} />
      // case ContentItem.security:
      //   return <SecuritySettings {...props} />
      case ContentItem['user/workspace']:
        return <WorkspaceSettings {...props} />
    }
  }
  
  return <NavigationDrawer menu={<MenuList disablePadding>
    { Object.values(ContentItem).map((item, index) => <MenuItem key={item} selected={content == item} onClick={() => navigate(`#${Object.keys(ContentItem)[index]}`)}>
      <ListItemIcon>{renderMenuIcon(item)}</ListItemIcon>
      <ListItemText>{item}</ListItemText></MenuItem>)}
    </MenuList>} currentContent={<Container maxWidth="md">{renderCurrentContent(content)}</Container>}
  />;
};

const SecuritySettings = (props: StartProps & ToolboxProps) => {
  return <>
    <FormGroup>
      <p><strong>Change Password</strong></p>
    </FormGroup>

    <FormGroup>
      <TextField name="old_password" required label="Current Password" variant="filled" type="password" />
    </FormGroup>

    <PasswordForm onChange={() => {}} />

    <Stack direction="row" spacing={1} justifyContent="center">
      <Button variant="text" color="secondary" onClick={props.onClose}>Cancel</Button>
      <Button variant="outlined" color="primary" type="submit">Save</Button>
    </Stack>
  </>
}

const ProfileSettings = (props: StartProps & ToolboxProps) => {

  const [isSaving, shouldSave] = useState<boolean>(false);
  const [params, setParams] = useState<UserOnboardingParams>({
    display_name: props.user.display_name
  });

  useEffect(() => {
    if (!isSaving) { return }
    Api.put(`/user`, params).then(result => {
      props.onClose();
      window.location.reload();
    });
  }, [isSaving]);

  return <>
    {/* <FormGroup>
      <p><strong>Account</strong></p>
    </FormGroup> */}

    <FormGroup>
      <TextField name="display_name" required label="Display Name" value={params.display_name} variant="filled" onChange={e => setParams({ ...params, display_name: e.target.value })} />
    </FormGroup>

    <FormGroup>
      <TextField name="email" type="email" disabled required label="Email" variant="filled" defaultValue={atob(props.user.email)} />
    </FormGroup>

    {/* <FormGroup>
      <p><strong>Company Role</strong></p>
    </FormGroup>

    <FormGroup>
      <TextField name="position_name" label="Current Position" defaultValue={props.user.position_name} variant="filled" />
    </FormGroup>

    <FormGroup>
      <TextField name="position_description" label="Main Responsibility" defaultValue={props.user.position_description} variant="filled" />
    </FormGroup> */}

    <Stack direction="row" spacing={1} justifyContent="center">
      <Button variant="text" color="secondary" onClick={props.onClose}>Cancel</Button>
      <LoadingButton variant="outlined" color="primary" loading={isSaving} onClick={() => shouldSave(true)}>Save</LoadingButton>
    </Stack>
  </>
}

const WorkspaceSettings = (props: StartProps & ToolboxProps) => {

  const [willLeave, wantLeave] = useState<boolean>(false);
  const [leave, shouldLeave] = useState<boolean>(false);

  useEffect(() => {
    if (!leave) { return }
    Api.delete(`/user`).then(result => {
      window.location.reload();
    });
  }, [leave]);

  return <>
    <FormGroup>
      <Button variant="text" color="error" onClick={() => wantLeave(true)} disabled={Role[props.user.role] === Role.admin}>Leave workspace</Button>
    </FormGroup>

    { willLeave && <ConfirmDialog title="Leave workspace" 
                                message="Do you really want to leave this workspace?"
                                onCancel={() => wantLeave(false)}
                                onConfirm={() => shouldLeave(true)} /> }
  </>
}

export default Settings;