import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  AppBar,
  IconButton,
  Toolbar,
  Collapse,
  MenuList,
  MenuItem,
  Card,
  FormGroup,
  Stack,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Logo from "../Shared/Logo";
import ConsentScripts from "../../utils/ConsentScripts";
import MenuIcon from "@mui/icons-material/Menu";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import RocketLaunchTwoToneIcon from "@mui/icons-material/RocketLaunchTwoTone";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PricingBoxes from "../Shared/PricingBoxes";

const Landingpage = () => {
  const [menuShown, showMenu] = useState(false);

  const MenuItems = (props) => {
    return (
      <MenuList
        sx={{
          display: props.responsive ? { xs: "none", md: "flex" } : "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          gap: { sx: "1rem", md: "2rem" },
          flexGrow: 1,
        }}
      >
        {/* <MenuItem component={NavLink} disableRipple>
          Features
        </MenuItem>
        <MenuItem component={NavLink} disableRipple>
          Use Cases
        </MenuItem>
        <MenuItem component={NavLink} disableRipple>
          Pricing
        </MenuItem>
        <MenuItem component={NavLink} disableRipple>
          FAQ
        </MenuItem> */}

        <Stack
          direction="row"
          sx={{ marginLeft: "auto", display: { xs: "none", md: "flex" } }}
          spacing={1}
        >
          <Button
            component={NavLink}
            variant="text"
            size="small"
            to="/login"
          >
            Sign in
          </Button>
          <Button
            component={NavLink}
            variant="outlined"
            color="secondary"
            size="small"
            to="/signup"
          >
            Start now
          </Button>
        </Stack>

        <MenuItem
          component={NavLink}
          to="/login"
          sx={{ display: { md: "none" } }}
        >
          Sign in
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <div id="landingpage" className="bg-white">
      <ConsentScripts />

      <AppBar component="nav" position="relative">
        <Toolbar sx={{ gap: "3rem", minHeight: "80px !important" }}>
          <Logo color="primary" icon />
          <IconButton
            sx={{ marginLeft: "auto", display: { md: "none" } }}
            onClick={() => showMenu((shown) => !shown)}
          >
            <MenuIcon />
          </IconButton>
          <MenuItems responsive={true} />
        </Toolbar>
      </AppBar>

      <Collapse
        in={menuShown}
        className="bg-white"
        sx={{ flexGrow: 1, display: { md: "none" } }}
      >
        <MenuItems />
      </Collapse>

      <header>
        <Container maxWidth="lg text-center">
          <h1>AI-driven lessons learned copilot</h1>
          <ListItemText><span className="text-secondary">
            Transforming lessons learned into success
          </span></ListItemText>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/signup"
            sx={{ mt: "1rem" }}
            endIcon={<ArrowRightAltIcon />}
          >
            Try it out
          </Button>
        </Container>
      </header>

      {/* <section id="features">
        <Container maxWidth="lg" className="text-center">
          <Grid container className="text-primary">
            <Grid
              item
              md={2}
              sx={{ display: { xs: "none", md: "flex" } }}
            ></Grid>
            <Grid item xs={12} md={4} sx={{ padding: "1rem" }}>
              <Card className="bg-grey" elevation={0}>
                <AssuredWorkloadIcon fontSize="large" color="warning" />
                <h3>GDPR &amp; AI Act Compliant</h3>
                <p>Securely hosted in the EU</p>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sx={{ padding: "1rem" }}>
              <Card className="bg-grey" elevation={0}>
                <GppGoodIcon fontSize="large" color="info" />
                <h3>Data Secure</h3>
                <p>Encrypted service</p>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sx={{padding: '1rem'}}>
            <Card className="bg-grey" elevation={0}>
              <RocketLaunchTwoToneIcon fontSize="large" color="error" />
              <h3>Integrated</h3>
              <p>Connect with your PM tool</p>
            </Card>
          </Grid>
          </Grid>
        </Container>
      </section> */}

      {/* <section id="bullets" className="text-center">
      <h2>So funktioniert's</h2>
      <p className="text-lg">Beschreibung...</p>
    </section> */}

      <section id="pricing" className="bg-secondary">
        <Container maxWidth="lg">
          <FormGroup className="text-center" sx={{ mb: "2rem" }}>
            <h2>Get your lessons learned to the next level</h2>
          </FormGroup>

          <PricingBoxes />
        </Container>
      </section>

      {/* <section id="faq" className="text-center">
      <h2>FAQ</h2>
      <p className="text-lg">Beschreibung...</p>
    </section> */}

      <footer className="bg-dark">
        <Container maxWidth="lg">
          <Grid container sx={{ alignItems: "center" }} spacing={1}>
            <Grid item xs={12} md={4}>
              <Logo color="white" />
              <p className="text-white">
                <small>Copyright &copy;{new Date().getFullYear()} Innomaze FlexCo.</small>
              </p>
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack
                direction={{xs: "column", md: "row"}}
                spacing={{xs: 1, md: 6}}
                sx={{ justifyContent: "flex-end" }}
              >
                <a href="/terms"><small className="text-white">Terms of service</small></a>
                <a href="/privacy"><small className="text-white">Privacy policy</small></a>
                <a href="/beta"><small className="text-white">Beta test agreement</small></a>
                <a href="/legal"><small className="text-white">Legal notice</small></a>
                <a href="/legal"><small className="text-white">Contact</small></a>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  );
};

export default Landingpage;
