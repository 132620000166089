import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import FormUtils from "../../utils/FormUtils";
import { FormGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ForgotPwdForm = (props) => {

  const [isLoading, shouldLoad] = useState(false);

  return (
    <form method="post" action="/login/reset" onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>
      <FormGroup>
        <p>Gib deine Email Adresse ein und erhalte eine Email mit einem Link zum Zurücksetzen des Passworts.</p>
      </FormGroup>

      <FormGroup>
        <TextField name="email" type="email" required label="Email" variant="filled" />
      </FormGroup>

      <FormGroup>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>Passwort zurücksetzen</LoadingButton>
      </FormGroup>

      <FormGroup>
        <p className='text-center'><small><NavLink to="/login">Zurück zur Anmeldung</NavLink></small></p>
      </FormGroup>
    </form>
  );
};

export default ForgotPwdForm;