import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Box, Container, OutlinedInput } from "@mui/material";
import Api from "../../../network/Api";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import LessonsList from "./List";
import Lesson from "../../../types/Lessons";
import Importer, { ImportButtons } from "./Importer";
import { Accept } from "react-dropzone";
import ScrollControl from "../../Shared/ScrollControl";

type NewLessonProps = {
  defaultValue?: Array<Lesson>;
  onCompletion: () => void;
}

const NewLesson = (props: NewLessonProps) => {
  const [input, setInput] = useState<string>("");
  const [lessons, setLessons] = useState<Array<Lesson>>(props.defaultValue || []);
  const [isSaving, shouldSave] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [importing, shouldImport] = useState<Accept>();

  const initialLoad = useRef(true);
  const scrollTopRef = useRef();
  const scrollBottomRef = useRef();
  const [refsSet, setRefsSet] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
  }, [lessons]);

  useEffect(() => {
    if (input === "" || typeof input === "undefined") {
      if (scrollBottomRef.current) {
        scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [input]);

  useEffect(() => {
    if (scrollTopRef.current && scrollBottomRef.current) {
      setRefsSet(true);
    }
  }, [scrollTopRef.current, scrollBottomRef.current]);

  const addLesson = (input: string): Lesson | null => {
    if (typeof input === "undefined" || !input.replace(/\s/g, "").length) {
      return null;
    }
    const newLesson: Lesson = {
      id: `${new Date().getTime()}_${Math.random()}`,
      learned: input,
      state: { isAnalyzed: false },
      comments: 0
    };
    setLessons((current) => [...current, newLesson]);
    setInput("");
    return newLesson;
  };

  const updateLesson = (lesson: Lesson) => {
    setLessons((current) => {
      return current.map((item) => {
        if (item.id != lesson.id) {
          return item;
        }
        return lesson;
      });
    });
  };

  const removeLesson = (lesson: Lesson) => {
    setLessons((current) => {
      return current.filter((item) => item.id != lesson.id);
    });
  };

  const addAndClassifyLessons = (lessons: Array<string>) => {
    let addedLessons = lessons.map((lesson) => {
      return addLesson(lesson);
    });
    classify(addedLessons);
  };

  const classify = (newLessons: Array<Lesson | null>) => {
    if (newLessons.length <= 0) { return }
    setIsClassifying(true);
    Api.post("/labels", { lessons: newLessons }).then((result) => {
      result.forEach((labels) => {
        let existing = newLessons.find((lesson) => lesson?.id == labels.id);
        updateLesson({
          ...existing,
          ...labels,
          state: { ...existing?.state, isAnalyzed: true },
        });
      });
      setIsClassifying(false);
    });
  };

  const onLessonsStored = () => {
    props.onCompletion();
    navigate("/app#completed");
  };

  useEffect(() => {
    if (!isSaving || lessons.length <= 0) {
      return;
    }
    Api.post(`/lessons`, { lessons: lessons }).then((result) => {
      if (result?.error) {
        shouldSave(false);
        return;
      }
      onLessonsStored();
    });
  }, [isSaving]);

  return (
    <Stack
      className="animate__animated animate__fadeIn"
      marginY={"auto"}
      flexGrow={lessons.length > 0 ? 1 : 0}
      width="100%"
    >
      <Stack
        sx={{
          flex: lessons.length > 0 ? "1 1 0" : 1,
          overflowY: "auto",
          py: 4,
          alignItems: "center",
        }}
      >
        <Container maxWidth="md">
          <Box ref={scrollTopRef} />
          <Stack spacing={2}>
            <LessonsList
              lessons={lessons}
              isAnalyzing={isClassifying}
              editable={!isSaving}
              onChange={updateLesson}
              onRemove={removeLesson}
            />
          </Stack>
          <Box ref={scrollBottomRef} />
        </Container>
      </Stack>

      { refsSet && <ScrollControl scrollTopRef={scrollTopRef} scrollBottomRef={scrollBottomRef} /> }

      <Container maxWidth="md" sx={{mb: 3}}>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={2}
          sx={{ alignItems: "center" }}
        >
          <OutlinedInput
            placeholder="Enter your lessons learned one by one"
            fullWidth
            sx={{ flex: 1, pr: 0 }}
            autoComplete="off"
            name="lesson"
            startAdornment={<ImportButtons onImport={shouldImport} />}
            endAdornment={
              <Button
                size="small"
                sx={{ ml: "0.5rem" }}
                onClick={() => {
                  if (input === "") {
                    return;
                  }
                  addAndClassifyLessons([input])
                }}
              >
                <SendIcon />
              </Button>
            }
            value={input}
            onChange={(e) => setInput(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (input === "" || e.key !== "Enter") {
                return;
              }
              addAndClassifyLessons([input])
            }}
          />

          {lessons.length > 0 && (
            <LoadingButton
              variant="outlined"
              loading={isSaving}
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={() => shouldSave(true)}
            >
              Save {lessons.length} items
            </LoadingButton>
          )}
        </Stack>
      </Container>

      {importing && (
        <Importer
          accept={importing}
          onCancel={() => shouldImport(undefined)}
          onEdit={(lessons) => {
            shouldImport(undefined);
            addAndClassifyLessons(lessons);
          }}
          onUploaded={onLessonsStored}
        />
      )}
    </Stack>
  );
};

export default NewLesson;
