import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, FormGroup, Tooltip, IconButton, Stack, TextFieldProps } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TypingIndicator from "./TypingIndicator";
import ClearIcon from '@mui/icons-material/Clear';

type MultilineTextFieldProps = {
  onChange: (value: string) => void;
  onSave: (value: string) => void;
  onAiSuggest?: () => void;
};

export const MultilineTextField = (props: MultilineTextFieldProps & TextFieldProps) => {
  const [isSuggesting, shouldSuggest] = useState<boolean>(false);

  useEffect(() => {
    if (!isSuggesting) return;
    props.onAiSuggest?.();
  }, [isSuggesting]);

  useEffect(() => {
    shouldSuggest(false);
  }, [props.value]);

  return (<TextField
    multiline
    fullWidth
    rows={3}
    variant="filled"
    {...props}
    onChange={event => props.onChange(event.currentTarget.value)}
    onBlur={e => props.onSave(e.currentTarget.value)}
    InputProps={props.onAiSuggest && {
      sx: {alignItems: 'flex-start'},
      endAdornment: <>{ isSuggesting ? <TypingIndicator /> : 
        <Stack>
          <Tooltip title="Let AI suggest">
            <IconButton onClick={shouldSuggest} disabled={props.disabled}>
              <AutoFixHighIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          { props.value && <Tooltip title="Clear input">
            <IconButton onClick={() => {
              props.onChange("");
              props.onSave("");
            }}>
              <ClearIcon fontSize='small' />
            </IconButton></Tooltip> }
        </Stack> }</>
    }}
  />)
};

export const PasswordForm = (props) => {

  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordConfirmationHasError, setPasswordConfirmationHasError] = useState(false);

  const checkPasswords = (event) => {
    let password = document.getElementById("password")?.value;
    let passwordConfirmation = document.getElementById("password-confirmation")?.value;
    setPasswordHasError(password.length < 8);
    setPasswordConfirmationHasError(password != passwordConfirmation);
    if (props.onChange !== undefined) { props.onChange() }
  }

  return(<>
    <FormGroup>
      <TextField 
        id="password" 
        type="password" 
        name="password"
        error={passwordHasError}
        required
        helperText="Min. 8 characters."
        label="New Password"
        variant="filled"
        onChange={checkPasswords}
        sx={{marginBottom: '0.15rem'}} />

      <TextField 
        id="password-confirmation"
        type="password"
        name="password_confirmation" 
        error={passwordConfirmationHasError} 
        required 
        helperText="Passwords must match." 
        label="Repeat Password" 
        variant="filled" onChange={checkPasswords} />
    </FormGroup>
  </>);
}

export const ItemSelector = (props) => {
  
  return (
    <TextField
      name={props.name} label={props.label} defaultValue={props.defaultValue}
      select
      variant="filled"
      onChange={props.onChange}
      required={props.required}
    >
      {props.items?.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
}