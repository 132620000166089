import React from "react";
import {
  Alert,
  Button,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  FormLabel,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Lesson from "../../../types/Lessons";
import { Sentiment, Tagging } from "./Labeling";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TypingIndicator from "../../Shared/TypingIndicator";

type LessonsListProps = {
  lessons: Array<Lesson>;
  isAnalyzing: boolean;
  editable: boolean;
  onChange: (lesson: Lesson) => void;
  onRemove: (lesson: Lesson) => void;
};

const LessonsList = (props: LessonsListProps) => {
  return (
    <>
      <h3>
        Enter your lessons learned, either one by one, or use the importer to
        bulk-add items. Don't forget to press the save button when you're done.
      </h3>

      {props.lessons.length > 0 && (
        <List>
          {props.lessons.map((lesson) => {
            return (
              <ListItem
                component={Paper}
                className="animate__animated animate__fadeIn"
                key={lesson.id}
                sx={{ mb: 2, alignItems: "flex-start" }}
              >
                <ListItemIcon>
                  {props.editable && (
                    <IconButton onClick={() => props.onRemove(lesson)}>
                      <DeleteOutlineIcon color="secondary" fontSize="small" />
                    </IconButton>
                  )}
                </ListItemIcon>

                <Stack direction="row" justifyContent="space-between" flexGrow={1} spacing={3} my={0.5}> 

                  <ListItemText sx={{flexGrow: 1}}>{lesson.learned}</ListItemText>

                  <Stack spacing={0.5} sx={{ my: 2 }} minWidth="50%">
                    {lesson.state?.isAnalyzed || !props.isAnalyzing ? (
                      <>
                        <FormLabel>
                          Sentiment:{" "}
                          {<Sentiment {...lesson} />}
                        </FormLabel>
                        <FormLabel>
                          Related topics:
                        </FormLabel>
                        <Tagging
                          lesson={lesson}
                          onChange={(lesson) => props.onChange(lesson)}
                        />

                        {lesson.similar &&
                        typeof lesson.state?.isSimilar === "undefined" ? (
                          <Alert severity="warning" sx={{mt: 1}}>
                            Is this similar to{" "}
                            <strong>{lesson.similar_content}</strong>?
                            <Stack direction="row">
                              <Button
                                size="small"
                                variant="text"
                                startIcon={<CheckIcon />}
                                onClick={() =>
                                  props.onChange({
                                    ...lesson,
                                    state: {
                                      ...lesson.state,
                                      isSimilar: true,
                                    },
                                  })
                                }
                              >
                                Yes
                              </Button>
                              <Button
                                size="small"
                                variant="text"
                                startIcon={<CloseIcon />}
                                onClick={() =>
                                  props.onChange({
                                    ...lesson,
                                    similar: undefined,
                                    similar_content: undefined,
                                    state: {
                                      ...lesson.state,
                                      isSimilar: false,
                                    },
                                  })
                                }
                              >
                                No
                              </Button>
                            </Stack>
                            <small>
                              Pressing <strong>Yes</strong> connects the new
                              lessons learned with the existing one.
                            </small>
                          </Alert>
                        ) : lesson.state?.isSimilar === true ? (
                          <Alert sx={{mt: 1}} severity="success">
                            Will be merged with &laquo;
                            {lesson.similar_content}&raquo;
                          </Alert>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <TypingIndicator />
                    )}
                  </Stack>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

export default LessonsList;
