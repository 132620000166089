import React, { useState, useEffect } from "react";
import { Fab, Box } from '@mui/material';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export type ScrollProps = {
  scrollTopRef: React.MutableRefObject<any>;
  scrollBottomRef: React.MutableRefObject<any>;
}

const ScrollControl = (props: ScrollProps) => {
  const [isTopVisible, setIsTopVisible] = useState(false);
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  useEffect(() => {
    observe(props.scrollTopRef, setIsTopVisible);
  }, [props.scrollTopRef]);

  useEffect(() => {
    observe(props.scrollBottomRef, setIsBottomVisible);
  }, [props.scrollBottomRef]);

  const observe = (ref: React.MutableRefObject<any>, setVisibility: (isVisible: boolean) => void) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisibility(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  };

  return (
    <Box className="text-center" sx={{
      margin: !isTopVisible || !isBottomVisible ? "-38px 0 20px !important" : "-8px 0 0 !important",
      position: "relative",
      width: "100%",
      background: "linear-gradient(transparent 0, var(--background-secondary) 100%)"
    }}>
      { !isTopVisible && isBottomVisible && props.scrollTopRef.current && <Fab size="small" onClick={() => props.scrollTopRef.current.scrollIntoView({ behavior: "smooth" }) }><ArrowUpwardIcon fontSize="small" /></Fab> }
      { !isBottomVisible && props.scrollBottomRef.current && <Fab size="small" onClick={() => props.scrollBottomRef.current.scrollIntoView({ behavior: "smooth" }) }><ArrowDownwardIcon fontSize="small" /></Fab> }
    </Box>
  );
}

export default ScrollControl;