import Sheet from "./Sheet";
import Comment from "./Comment";

export enum SentimentType {
  positive,
  negative,
  neutral,
}

type Lesson = {
  id: string;
  learned: string;
  sheet?: Sheet;
  sentiment?: SentimentType;
  labels?: Array<string>;
  similar?: string;
  similar_content?: string;
  state: LessonState;
  comments: number;
};

type LessonState = {
  isAnalyzed: boolean;
  isSimilar?: boolean;
};

export default Lesson;
