import React from "react";
import { Card, FormGroup } from "@mui/material";

const Terms = () => {
  return (
    <Card>
      <FormGroup>
      <h2>Terms of service</h2>
</FormGroup>
      <FormGroup>
      <h3>1. Introduction</h3>
      <p>Welcome to Lessonice, a service provided by Innomaze FlexCo (“we,” “us,” or “our”). By accessing or using our service, you agree to these Terms & Conditions. If you do not agree, please refrain from using the service.</p>
</FormGroup>
      <FormGroup>
      <h3>2. Beta Program Disclaimer</h3>
      <p>Lessonice is currently in its beta phase. During this time:</p>
      <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
          <li className="text-primary">Service availability is not guaranteed.</li>
          <li className="text-primary">Responses may be inaccurate, incomplete, or misleading.</li>
          <li className="text-primary">The system may produce random or unexpected results.</li>
          <li className="text-primary">Users must not rely on the platform for critical decision-making.</li>
      </ul>
      </FormGroup>
      <FormGroup>
      <h3>3. Service Scope</h3>
      <p>Lessonice aims to assist with collecting and analyzing lessons learned within an organization using artificial intelligence. However, the platform:</p>
      <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
          <li className="text-primary">Does not guarantee the correctness, completeness, or reliability of its data.</li>
          <li className="text-primary">Should be used only for non-critical purposes during its beta phase.</li>
      </ul>
      </FormGroup>
      <FormGroup>
      <h3>4. Limitations of Liability</h3>
      <p>We are not liable for:</p>
      <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
          <li className="text-primary">Any inaccuracies or errors in the content provided by the service.</li>
          <li className="text-primary">Any decisions made based on the service's output.</li>
          <li className="text-primary">Losses or damages resulting from system downtime or performance issues.</li>
      </ul>
      </FormGroup>
      <FormGroup>
      <h3>5. User Obligations</h3>
      <p>Users agree to:</p>
      <ul style={{margin: 0, padding: "0.5rem 2rem"}}>
          <li className="text-primary">Use the service responsibly and within the limits defined by this agreement.</li>
          <li className="text-primary">Refrain from relying on the platform for legally binding or critical business decisions.</li>
      </ul>
      </FormGroup>
      <FormGroup>
      <h3>6. Modifications</h3>
      <p>We reserve the right to update these terms at any time. Continued use of the service implies acceptance of any updates.</p>
      </FormGroup>
      <FormGroup>
      <h3>7. Governing Law</h3>
      <p>This agreement is governed by the laws of Austria.</p>
      </FormGroup>
    </Card>
  );
};

export default Terms;
